.progress-bar {
  --step: 0/1;
  --step-percent: calc(var(--step) * 100%);

  width: 100%;
  height: 5px;
  background: linear-gradient(to right, var(--colour-progress-bar-fill) var(--step-percent), var(--colour-progress-bar-bg) var(--step-percent));
}

.progress-bar.is-loading-indeterminate {
  --step: 1/2;
  $fade: 0.5s;
  $fade-delay: 0.5s;
  $bar-speed: 1.2s;
  $bar-delay: $fade + $fade-delay;

  opacity: 0;
  background: var(--colour-progress-bar-bg);
  animation: fade $fade $fade-delay ease-out 1 forwards;

  &::after {
    content: "";
    display: block;
    height: 100%;
    width: calc(var(--step) * 100%);
    background: var(--colour-progress-bar-loading-fill);
    transform: translateX(calc(var(--step) * -100vw));
    animation: bar $bar-speed $bar-delay cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
    transform-origin: left;
  }

  @include is-desktop {
    --step: 1/3;
    $bar-speed: 2s;
  }

  @keyframes fade {
    to {
      opacity: 1;
    }
  }

  @keyframes bar {
    to {
      transform: translateX(100vw) scaleX(2);
    }
  }
}
