.line-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-sm;
  align-items: center;

  &:not(.has-full-description) {
    gap: $space-xs;
  }

  .additional-cost {
    &.negative {
      &::before {
        content: '- ';
      }
    }

    &.positive {
      &::before {
        content: '+ ';
      }
    }

    @include hidden-when-empty;
  }

  .value {
    min-width: 6ch;
    text-align: right;
  }

  label {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    gap: $space-xs;

    .label-text {
      flex: 1;
    }
  }

  .checkbox,
  .radio {
    display: contents;
    margin-bottom: 0px;

    & span.additional-cost {
      white-space: nowrap;
    }
  }

  .quantity-input {
    margin-bottom: 0px;
    height: px-to-rem(46px);

    input[type="number"] {
      padding: px-to-rem(6px);
    }
  }

  &.is-large {
    font-size: $font-size-xl;
  }

  .thumbnail-image {
    width: 50px;
    height: 50px;
  }

  .thumbnail-image[style*="background-image"] {
    position: relative;
    background-position: center;
    background-size: cover;
  }
}

.line-item.is-secondary {
  border-radius: 5px;
  padding: $space-xs $space-xs $space-xs $space-sm;
  background-color: var(--colour-line-item-secondary-bg);

  label {
    font-size: $font-size-xs;
  }
}

.line-item.is-disabled {
  @include muted-text;

  .thumbnail-image {
    filter: grayscale(1);
    opacity: 0.5;
  }
}

.line-item.is-removed {
  .label-text,
  .additional-cost {
    text-decoration: line-through;
  }

  .thumbnail-image {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: hsla(0, 0%, 100%, 0.7);
    }

    &::after {
      content: "";
      position: absolute;
      height: 24px;
      width: 24px;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M20.5 3.5a12 12 0 1 0-17 17 12 12 0 0 0 17-17ZM5.3 5.3a9.6 9.6 0 0 1 12.3-1 .3.3 0 0 1 0 .4l-13 12.9a.3.3 0 0 1-.3 0 9.5 9.5 0 0 1 1-12.3Zm13.4 13.5a9.5 9.5 0 0 1-12.3.9.3.3 0 0 1 0-.3l13-13a.3.3 0 0 1 .4.1 9.5 9.5 0 0 1-1 12.3Z' fill='%23000'/%3E%3C/svg%3E");
    }
  }
}

.line-item.has-full-description {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: $space-md;
  row-gap: $space-sm;

  p {
    margin-block-end: 0;
  }

  &:not(:last-child) {
    padding-bottom: $space-md;

    @include divider-line;
  }

  .title {
    font-size: $font-size-md;
    font-weight: 500;
    margin-right: auto;
    line-height: 1.35;

    @include is-tablet {
      font-size: $font-size-lg;
    }
  }

  .description p {
    @include hidden-when-empty;
  }

  > :has(.title),
  .description,
  .item-options {
    width: 100%;
  }

  .price {
    font-weight: 500;
  }

  .chip.is-action {
    margin-left: $space-sm;
    align-self: center;
  }

  .badge {
    margin-left: $space-sm;
  }

  .button {
    margin-top: $space-sm;
  }
}
