.button {
  $padding: px-to-rem(15px) px-to-rem(24px);

  width: 100%;
  background-color: var(--colour-action-fill);
  color: var(--colour-action-text);
  font-weight: 500;
  font-size: $font-size-sm;
  padding: $padding;
  min-width: 13ch;
  line-height: 1;
  border: 1px solid var(--colour-action-border);
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  appearance: none;
  vertical-align: top;
  gap: px-to-em(12px);

  @include animate-subtle;

  @include is-tablet {
    width: auto;
  }

  @include has-mouse-pointer {
    cursor: pointer;

    &:not(.is-loading):hover {
      background-color: var(--colour-action-hover);
      transform: translateY(-2px);
      text-decoration: none;
    }

    &:not(.is-loading):active {
      transform: translateY(0px);
    }
  }

  &.has-auto-width {
    width: auto;
  }

  &.is-justified-left {
    justify-content: flex-start;
    padding: 1em;

    @include is-tablet {
      justify-content: center;
      padding: $padding;
    }
  }

  &.has-space-between {
    justify-content: space-between;
    text-align: left;
  }

  &.is-full-width {
    width: 100%;
    transform: none;
  }

  @include remove-default-tap-highlights;

  &:focus-visible {
    outline: 4px solid var(--colour-focus-outline);
    outline-offset: 3px;
  }

  @supports not selector(:focus-visible) {
    &:focus {
      outline: 4px solid var(--colour-focus-outline);
      outline-offset: 3px;
    }
  }
}

.button.is-primary {
  background-color: var(--colour-action-primary);
  border-color: var(--colour-action-primary);
  color: var(--colour-action-primary-text);

  @include has-mouse-pointer {
    &:not(.is-loading):hover {
      background-color: var(--colour-action-primary-hover);
      border-color: var(--colour-action-primary-hover);
    }
  }
}

.button.is-raised {
  background-color: var(--colour-action-raised-fill);
  border-color: var(--colour-action-raised-fill);
  color: var(--colour-action-raised-text);

  @include elevation-middle;

  @include has-mouse-pointer {
    &:hover {
      background-color: var(--colour-action-raised-hover);
      border-color: var(--colour-action-raised-hover);
    }
  }
}

.button.is-green {
  background-color: #97EDB4;
}

.button.is-compact {
  min-width: revert;
}

.button.is-icon-only {
  min-width: revert;
  width: $button-icon-only-size;
  height: $button-icon-only-size;
  border-radius: 50%;
  padding: 0;
  font-size: px-to-rem(14px);
  flex-shrink: 0;

  &.is-ghost {
    background-color: transparent;
    border-color: transparent;
    color: var(--colour-action-ghost-text);
    box-shadow: none;

    @include has-mouse-pointer {
      &:hover {
        background-color: var(--colour-action-hover);
        border-color: var(--colour-action-border);
        color: var(--colour-text);
      }
    }

    &.is-raised {
      @include has-mouse-pointer {
        &:hover {
          background-color: var(--colour-action-raised-fill);
          border-color: var(--colour-action-raised-fill);
          @include elevation-middle;
        }
      }
    }
  }

  &.has-large-icon {
    font-size: px-to-rem(18px);
  }

  &.has-plain-icon {
    color: inherit;
  }

  &.is-tiny {
    font-size: px-to-rem(10px);
    position: relative;
    border: none;
    background-color: initial;

    &::before,
    .icon {
      transform: translateY(0px);
    }

    &::before {
      content: "";
      position: absolute;
      border-radius: inherit;
      width: px-to-rem(24px);
      height: px-to-rem(24px);
      border: 1px solid transparent;
    }

    &[disabled] {
      &:not(.is-loading),
      &.is-loading.is-visually-disabled {
        &::before {
          background-color: var(--colour-action-disabled) !important;
        }
      }
    }

    &:not(:is(.is-primary, .is-ghost)) {
      &::before {
        background-color: var(--colour-action-fill);
        border-color: var(--colour-action-border);
      }

      @include has-mouse-pointer {
        &:not(.is-loading):hover::before {
          background-color: var(--colour-action-hover);
        }
      }
    }

    &.is-primary {
      background-color: initial;

      &::before {
        background-color: var(--colour-action-primary);
        border-color: var(--colour-action-primary);
        color: var(--colour-action-primary-text);
      }

      @include has-mouse-pointer {
        &:not(.is-loading):hover::before {
          background-color: var(--colour-action-primary-hover);
          border-color: var(--colour-action-primary-hover);
        }
      }
    }

    &.is-raised {
      box-shadow: none;

      &::before {
        @include elevation-low;
      }
    }

    &.is-ghost {
      background-color: initial;

      @include has-mouse-pointer {
        &:hover::before {
          background-color: var(--colour-action-hover);
          border-color: var(--colour-action-border);
          color: var(--colour-text);
        }
      }
    }

    @include has-mouse-pointer {
      &::before,
      .icon {
        @include animate-subtle;
      }

      &:not(.is-loading):hover {
        transform: none;
      }

      &:not(.is-loading):hover::before,
      &:not(.is-loading):hover .icon {
        transform: translateY(-2px);
      }

      &:not(.is-loading):active::before,
      &:not(.is-loading):active .icon {
        transform: translateY(0px);
      }
    }
  }
}

.button.is-loading .icon {
  height: 1rem;
  display: flex;
  align-items: center;
}

.button.is-loading .hide-while-loading {
  display: none;
}

.button:not(.is-loading) .show-while-loading {
  display: none;
}

.button[disabled] {
  cursor: not-allowed;
  transform: none !important;

  &:not(.is-loading),
  &.is-loading.is-visually-disabled {
    color: var(--colour-text-muted) !important;
    border-color: var(--colour-action-border) !important;

    &:not(.is-tiny) {
      background-color: var(--colour-action-disabled) !important;
    }
  }
}

.button-group {
  display: inline-flex;
  width: 100%;
  gap: $space-xs;

  form, .popover-sheet {
    display: contents;
  }
}

.is-floating-button {
  position: sticky;
  top: 0;
  padding: $floating-back-button-spacing-y 0;
  z-index: $layer-low;
  width: fit-content;

  &.is-floating-left {
    @include horizontal-container-spacing('padding-left');
  }

  &.is-floating-right {
    margin-left: auto;

    @include horizontal-container-spacing('padding-right');
  }
}

.image-button {
  > img {
    @include animate-subtle;
  }

  @include remove-hover-underline;

  @include has-mouse-pointer {
    &:hover > img {
      transform: translateY(-2px);
    }

    &:active > img {
      transform: translateY(0px);
    }
  }
}