.list {
  display: flex;
  flex-direction: column;

  &.has-hoverable-items {
    .list-item {
      @include has-mouse-pointer {
        &:hover {
          background-color: var(--colour-action-hover);
        }
      }

      @include remove-hover-underline;
    }
  }

  .list-item {
    align-items: center;
    display: flex;
    position: relative;
    padding: $space-xs $space-md;
    gap: $space-sm;

    &:focus {
      background-color: var(--colour-action-hover);
      outline: none;
    }
  }
}
