@mixin modal-background {
  backdrop-filter: blur(4px);
  background-color: var(--colour-modal-shadow);
}

@mixin modal-initial-state {
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s cubic-bezier(0.46, 0.03, 0.52, 0.96);

  .card {
    transform: translateY(60px) scale(0.94);
    transition: all 0.1s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  }
}

@mixin modal-active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;

  .card {
    transform: translateY(0) scale(1);
    transition-delay: 0s;
  }
}

.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: $layer-highest;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .card {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    overscroll-behavior: none;

    @include is-desktop {
      &[style*="--cart-height-offset-desktop"] {
        max-height: calc(100vh - var(--cart-height-offset-desktop, 0) * 1px);
      }
    }

    & > form {
      display: contents;
    }

    > .controls {
      position: sticky;
      top: 0;
      padding: $floating-back-button-spacing-y 0;
      z-index: $layer-low;
      width: 100%;
      min-width: 0;
      display: flex;
      align-items: center;
      gap: $space-sm;

      @include is-desktop {
        justify-content: space-between;

        > :first-child {
          margin-right: auto;
        }

        > :last-child {
          margin-left: auto;
        }
      }

      > .badge {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @include horizontal-container-spacing;
    }
  }

  @include modal-initial-state;

  &.is-active {
    @include modal-active;
  }

  .is-floating-button > a {
    z-index: $layer-high;
  }

  .modal-header {
    width: 100%;
    background-color: var(--colour-bg-elevated);
    z-index: $layer-high;

    @include is-tablet {
      & + section {
        @include vertical-container-spacing('padding-top');
      }
    }
  }

  @include is-desktop {
    padding: $desktop-container-spacing;

    @include modal-background;

    .card {
      max-width: 800px;
      height: auto;
    }
  }
}

// Modals placed in grids with an aside are displayed as a standard modal for mobile
// but are displayed on the grid as a card with no back button for tablets and above.
// This can be removed once all stores use the bottom navigation.
.grid.has-aside .modal:not(.is-forced) {
  flex-direction: column;

  @include is-desktop {
    @include modal-active;
    display: contents;

    .card {
      height: auto;
    }

    .is-back-button-spacer,
    .modal-header {
      display: none;
    }
  }
}