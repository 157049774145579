:root {
  --bottom-nav-height: #{px-to-rem(76px)};
}

@keyframes menu-item-tap {
  0% {
    background-color: transparent;
    transform: translate(-50%, -50%) scale(0.5);
  }

  50% {
    background-color: var(--colour-bottom-nav-tap-highlight);
  }

  100% {
    background-color: transparent;
    transform: translate(-50%, -50%) scale(1);
  }
}

menu.bottom-nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(0px, 100%), 1fr));
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: var(--colour-bg-high-contrast);
  block-size: var(--bottom-nav-height);
  list-style: none;
  overflow: hidden;
  position: fixed;
  inset-inline: 0;
  inset-block-end: 0;
  z-index: $layer-high;

  @include elevation-high;

  li {
    // Round the width of each nav item to the nearest 2 pixels for working
    // around a Safari bug where the icons jump due to weird paint bugs or
    // something when the colour in the background changes due to tap indicator
    // effects
    --evenly-divisible-pixels: 2px;
    inline-size: round(100%, var(--evenly-divisible-pixels));

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      block-size: 100%;
      text-decoration: none;
      font-size: px-to-rem(14px);
      position: relative;
      border-radius: 5px;
      gap: 4px;

      @include remove-default-tap-highlights;

      > * {
        z-index: 1;
      }

      .icon {
        font-size: $font-size-sm;
        color: var(--colour-bottom-nav-icon);
        transition: color 0.1s;
      }

      > div {
        position: relative;

        .badge.is-quantity.is-tiny {
          position: absolute;
          inset-inline-end: px-to-rem(-14px);
          inset-block-start: px-to-rem(-12px);
          min-width: px-to-rem(20px);
          height: px-to-rem(20px);
        }
      }

      > span {
        padding-block: px-to-rem(3px);
        padding-inline: px-to-rem(7px);
        color: var(--colour-bottom-nav-text);
        transition: background-color 0.1s;

        @include round-radius;
      }

      // Pseudo element for tap highlights hidden by default
      &::before {
        content: "";
        position: absolute;
        block-size: 140%;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        background-color: transparent;
        transform: translate(-50%, -50%) scale(0.5);
        aspect-ratio: 1/1;

        @include round-radius;
      }

      // Use a tap highlight indicator animation when tapping an inactive nav item
      &:hover::before {
        animation: 0.32s menu-item-tap ease-out;
      }

      // Use a tap highlight indicator transition effect for subsequent taps on active menu items
      &:active::before {
        transition-duration: 0.32s;
        transition-timing-function: ease-out;
        transition-property: background-color, transform;
        background-color: var(--colour-bottom-nav-tap-highlight);
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  // Make sure the bottom nav element nav is never displayed on desktop
  @include is-desktop {
    display: none;
  }
}

// Offset storefront notices from bottom nav when bottom nav is visible
body:has(.notice.is-fixed-bottom ~ menu.bottom-nav) .notice.is-fixed-bottom {
  @include is-not-desktop {
    bottom: var(--bottom-nav-height);
  }
}

// A list of view names to match on for applying the active style.
$views: (
  "shop",
  "categories",
  "search",
  "cart"
);

@mixin active-bottom-nav-item {
  .icon {
    color: var(--colour-bottom-nav-icon-active);
  }

  > span {
    color: var(--colour-bottom-nav-text-active);
    background-color: var(--colour-bottom-nav-active);
  }
}

.app-view {
  overflow-y: auto;
  overscroll-behavior-y: contain;
  background-color: var(--colour-bg-high-contrast);
}

// Show only the shop / storefront view on desktop
@include is-desktop {
  .app-view:not(#cart) {
    display: none;
  }

  .app-view[style*="--cart-height-offset-desktop"] {
    max-height: calc(100vh - var(--cart-height-offset-desktop, 0) * 1px);
  }
}

// On mobile and tablet, decide which .app-view is displayed and nav item to set as active,
// defaulting to the main one when no nav item has been selected yet.
@include is-not-desktop {
  .app-view {
    position: fixed;
    inset-block-start: 0;
    inset-block-end: var(--bottom-nav-height);
    inset-inline: 0;
    display: flex;
    flex-direction: column;
    z-index: $layer-high;
    box-shadow: 0 var(--bottom-nav-height) 0 0 var(--colour-bg-high-contrast);

    &:has(.popover-sheet.is-active) {
      z-index: $layer-highest;
    }
  }

  body:has(menu.bottom-nav) section.catalog {
    padding-bottom: var(--bottom-nav-height);
  }

  body:not(:has(menu.bottom-nav)) .app-view {
    inset: 0;
  }

  @each $view in $views {
    body:not([data-active-bottom-nav-view="#{$view}"]) .app-view##{$view}:not(.is-active) { // can remove the :not() after all stores migrated to use the new bottom nav
      display: none;
    }

    body[data-active-bottom-nav-view="#{$view}"] menu a[data-view-name="#{$view}"] {
      @include active-bottom-nav-item;
    }
  }
}
