nav.tree-nav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ul {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    gap: $space-xs;
    margin: 0;
    padding: 0;
    list-style: none;

    li a {
      line-height: 1;
      padding-block: px-to-rem(9px);
      padding-inline: px-to-rem(11px);
      border-radius: 6px;
      display: inline-block;
      color: var(--colour-tree-nav-link-text);
      order: 2;

      @include remove-hover-underline;

      &:hover {
        background-color: var(--colour-tree-nav-link-hover);
      }

      &.is-active {
        color: var(--colour-tree-nav-link-text-invert);
        background-color: var(--colour-tree-nav-link-active);
      }
    }
  }

  details {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    summary {
      display: inline-flex;
      align-items: center;
      list-style: none;
      position: relative;

      &::-webkit-details-marker {
        display: none;
      }

      &::before, &::after {
        content: '';
        block-size: $button-icon-only-size;
        inline-size: $button-icon-only-size;
        margin-inline-end: px-to-rem(2px);
        cursor: pointer;
      }

      &::before {
        border-radius: 6px;
        position: absolute;
        order: 0;
      }

      &::after {
        transform: rotate(-90deg);
        mask: url("data:image/svg+xml,%3Csvg id='Bold' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-down-1%3C/title%3E%3Cpath d='M12,19.5a2.3,2.3,0,0,1-1.729-.78L.46,7.568A1.847,1.847,0,0,1,3.233,5.129l8.579,9.752a.25.25,0,0,0,.376,0l8.579-9.752A1.847,1.847,0,1,1,23.54,7.568L13.732,18.716A2.31,2.31,0,0,1,12,19.5Z'/%3E%3C/svg%3E");
        mask-size: 0.7em;
        mask-repeat: no-repeat;
        mask-position: center;
        transition: transform 0.25s ease-in-out;
        background-color: var(--colour-tree-nav-disclosure-button-icon);
        order: 1;
      }

      &:has(:not(a:hover)):hover::before {
        background-color: var(--colour-tree-nav-disclosure-button-hover);
      }
    }

    &[open] {
      > summary {
        &::after {
          transform: rotate(0deg);
        }
      }
    }

    ul {
      padding-block-start: $space-xs;
      padding-inline-start: $button-icon-only-size + px-to-rem(2px);
    }
  }

  @include is-desktop {
    details ul {
      padding-inline-start: $button-icon-only-size + px-to-rem(7px);
    }

    ul li a {
      padding-block: px-to-rem(12px);
      padding-inline: px-to-rem(16px);
    }

    details summary {
      &::before, &::after {
        block-size: $button-icon-only-size + px-to-rem(5px);
        inline-size: $button-icon-only-size + px-to-rem(5px);
      }
    }
  }
}
