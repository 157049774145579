@use "sass:math";

$text-input-padding-x: px-to-rem(12px);
$text-input-padding-y: px-to-rem(6px);
$input-control-spacing: px-to-rem(6px);

@mixin input-selectors {
  input,
  textarea,
  select {
    @content;
  }
}

@mixin input-helpers-and-errors {
  .helper,
  .error {
    line-height: 1.25;
    margin-top: $space-xs;
    text-align: left;
  }

  .helper {
    @include muted-text;
  }

  .error {
    color: var(--colour-text-input-error);
    display: none;
  }

  &.has-error {
    .error {
      display: block;
    }
  }
}

%input-label {
  display: flex;
  padding-bottom: $space-xs;
}

%input {
  display: flex;
  flex-direction: column;
  font-size: $font-size-sm;
  margin-bottom: $space-md;
  position: relative;

  label {
    @extend %input-label;
  }

  @include input-selectors {
    padding: $text-input-padding-y $text-input-padding-x;
    box-sizing: initial;
    border-width: 1px;
    border-radius: 5px;
    border-style: solid;
    border-color: transparent;
    outline: none;
    background-color: var(--colour-text-input-fill);
    box-shadow: inset 0px -1000px 0 -998px transparent;
    text-overflow: ellipsis;
    box-sizing: border-box;
    appearance: none;

    section.muted-section & {
      background-color: var(--colour-bg-high-contrast);
    }

    @include animate-subtle($properties: #{border, background-color, box-shadow});

    &:focus {
      border-color: var(--colour-text-input-focus-border);
    }

    &::placeholder {
      color: var(--colour-text-input-muted);
      text-overflow: ellipsis;
    }
  }

  > span {
    position: relative;

    @include input-selectors {
      width: 100%;
    }
  }

  &.has-error {
    @include input-selectors {
      &,
      &:focus {
        border-color: var(--colour-text-input-error);

        &::selection {
          background-color: var(--colour-text-input-error);
          color: var(--colour-text-invert);
        }
      }

      &:focus {
        box-shadow: inset 0px -1000px 0 -998px var(--colour-text-input-error);
      }
    }
  }

  &.has-hidden-label label {
    @include visually-hidden-element;
  }

  &.has-max-width {
    span,
    input,
    select {
      max-width: 65ch;
    }
  }
}

.text-input {
  @extend %input;

  input {
    line-height: 2;

    &:focus {
      box-shadow: inset 0px -1000px 0 -998px var(--colour-text-input-focus-underline);
    }

    // Custom clear button behaviour for inputs
    // Animate it's visibility
    &[data-action*="clearInput"]  {
      @include animate-subtle;
    }

    // Hide the clear button until valid text is entered
    &[required]:not(:valid) + .input-controls [data-action*="clearInput"] {
      @include hidden-element-can-animate;
    }

    // Remove browser default clear button element from search inputs
    // Internet explorer
    &[type="search"]::-ms-clear,
    &[type="search"]::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    // Webkit
    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance: none;
      appearance: none;
    }

    // Provides a way to specify a text input's right padding value to offset the input area when
    // using with input-controls. Value in characters. When using it should be set to roughly the same
    // number of characters as the button(s) inside the input-controls container
    &[style*="--input-control-character-offset"] {
      // Adds button padding and input control spacing to the specified character size
      padding-right: calc((var(--input-control-character-offset) * 1ch) + #{$text-input-padding-y} * 2 + #{$space-sm} * 2);
    }
  }

  &.is-ghost {
    input {
      &:not(:hover):not(:focus) {
        background-color: transparent;
        border-color: transparent;
      }

      &:focus {
        box-shadow: none
      }
    }
  }

  &.is-inline {
    @include is-tablet {
      display: inline-block;
    }

    // Width option for inline input elements, displays them at wide sizes for desktop & tablet
    &.has-large-initial-width {
      input {
        width: 100%;
      }

      @include is-tablet {
        width: 100%;
        max-width: 45ch;
      }

      @include is-desktop {
        max-width: 65ch;
      }
    }
  }

  // A container to place buttons on top of input
  // Make sure to add a padding offset to the input using --input-control-character-offset
  .input-controls {
    position: absolute;
    bottom: 0;
    right: 0;
    height: calc(2em + #{$text-input-padding-y} * 2 + 2px);
    display: flex;
    align-items: center;
    padding: $input-control-spacing;
    gap: $input-control-spacing;
    pointer-events: none;

    & > * {
      pointer-events: auto;
    }

    .button {
      min-width: revert;
      max-height: 100%;
      padding-left: $space-sm;
      padding-right: $space-sm;

      @include round-radius;
    }
  }

  @include input-helpers-and-errors;

  // Stripe input style
  .StripeElement {
    padding: $text-input-padding-y $text-input-padding-x;
    border-width: 1px;
    border-radius: 5px;
    border-style: solid;
    border-color: transparent;
    background-color: var(--colour-text-input-fill);
    box-shadow: inset 0px -1000px 0 -998px transparent;

    @include animate-subtle;

    &--focus {
      border-color: var(--colour-text-input-focus-border);
      box-shadow: inset 0px -1000px 0 -998px var(--colour-text-input-focus-underline);
    }

    &--invalid {
      border-color: var(--colour-text-input-error);
    }
  }

  &.has-max-width .StripeElement {
    max-width: 65ch;
  }
}

.select {
  @extend %input;

  select {
    line-height: 2;
    appearance: none;
  }

  &.is-inline {
    @include is-tablet {
      display: inline-block;
    }
  }

  @include input-helpers-and-errors;
}

.text-input.has-prefix-icon,
.select.has-prefix-icon,
.text-input.has-end-icon,
.select.has-end-icon {
  span {
    position: relative;
    display: flex;

    @include input-selectors {
      width: 100%;
    }

    > svg {
      font-size: px-to-rem(18px);
      align-self: center;
      position: absolute;
      pointer-events: none;
      color: var(--colour-text-input-icon);
    }
  }


  &.is-muted-icon span > svg {
    color: var(--colour-text-input-muted);
  }
}

.text-input.has-prefix-icon,
.select.has-prefix-icon {
  span {
    @include input-selectors {
      padding-left: (px-to-rem(12px) * 2) + px-to-rem(18px);
    }
  }

  svg:first-of-type {
    left: px-to-rem(12px);
  }
}

.text-input.has-end-icon,
.select.has-end-icon {
  span {
    @include input-selectors {
      padding-right: (px-to-rem(16px) * 2) + px-to-rem(18px);
    }
  }

  svg:last-of-type {
    right: px-to-rem(16px);
  }
}

.quantity-input {
  @extend .text-input;
  @extend .is-ghost;

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: px-to-rem(4px);

  label {
    @include visually-hidden-element;
  }

  input[type="number"] {
    width: 2ch;
    display: inline-block;
    box-sizing: content-box;
    text-align: center;
    text-overflow: initial;
    appearance: textfield;
    padding-left: px-to-rem(6px);
    padding-right: px-to-rem(6px);

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  .button {
    vertical-align: unset;
  }

  @include is-tablet {
    gap: $space-xs;

    input[type="number"] {
      padding-left: $text-input-padding-x;
      padding-right: $text-input-padding-x;
    }
  }
}

.textarea {
  @extend %input;

  textarea {
    max-width: 65ch;
    min-height: 5em;
    line-height: 1.5;
    resize: none;
  }

  &.is-resizable textarea {
    resize: vertical;
  }

  @include input-helpers-and-errors;
}

.input-group {
  display: flex;
  flex-wrap: wrap;

  > :not(:first-child) {
    margin-left: $space-sm;
  }

  .text-input {
    flex-grow: 1;
  }
}

.checkbox {
  $checkbox-size: px-to-rem(16px);
  $checkbox-border-thickness: px-to-rem(2px);
  $checkbox-border-radius: px-to-rem(2px);
  $label-line-height: 1.5;

  margin-bottom: $space-xs;

  label {
    position: relative;
    display: inline-block;
    line-height: $label-line-height;
    padding-left: $space-xs;
    padding-bottom: $space-xs;
    margin-left: $checkbox-size;

    &::before,
    &::after {
      content: "";
      width: $checkbox-size;
      height: $checkbox-size;
      position: absolute;
      right: 100%;
      top: calc((50% - (#{math.div($space-xs, 2)})) - (#{math.div($checkbox-size, 2)}));
      box-sizing: border-box;
    }

    &::before {
      border: $checkbox-border-thickness solid var(--colour-checkbox-outline);
      border-radius: $checkbox-border-radius;
    }

    &::after {
      mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Echeck%3C/title%3E%3Cpath d='M23.146,5.4,20.354,2.6a.5.5,0,0,0-.708,0L7.854,14.4a.5.5,0,0,1-.708,0L4.354,11.6a.5.5,0,0,0-.708,0L.854,14.4a.5.5,0,0,0,0,.707L7.146,21.4a.5.5,0,0,0,.708,0L23.146,6.1A.5.5,0,0,0,23.146,5.4Z'/%3E%3C/svg%3E");
      background-color: var(--colour-checkbox-checkmark);
      background-size: calc(100% - #{$checkbox-border-thickness});
      background-position: center;
      background-repeat: no-repeat;
      visibility: hidden;
      opacity: 0;
    }
  }

  input {
    @include visually-hidden-element;

    &:checked + label {
      &::before {
        background-color: var(--colour-checkbox-fill);
        border-color: var(--colour-checkbox-fill);
      }

      &::after {
        visibility: visible;
        opacity: 1;
      }
    }

    &:focus-visible + label::before {
      outline: 4px solid var(--colour-focus-outline);
      outline-offset: 3px;
    }

    @supports not selector(:focus-visible) {
      &:focus + label::before {
        outline: 4px solid var(--colour-focus-outline);
        outline-offset: 3px;
      }
    }
  }
}

.radio {
  $radio-size: px-to-rem(16px);
  $radio-border-thickness: px-to-rem(2px);
  $label-line-height: 1.5em;

  margin-bottom: $space-xs;

  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    line-height: $label-line-height;
    padding-left: $space-xs;
    padding-bottom: $space-xs;
    margin-left: $radio-size;

    &::before,
    &::after {
      content: "";
      width: $radio-size;
      height: $radio-size;
      position: absolute;
      top: calc((50% - (#{math.div($space-xs, 2)})) - (#{math.div($radio-size, 2)}));
      right: 100%;
      border-radius: $radio-size;
      box-sizing: border-box;
    }

    &::before {
      border: $radio-border-thickness solid var(--colour-radio-outline);
    }

    &::after {
      background-color: var(--colour-radio-checkmark);
      transform: scale(0.5);
      visibility: hidden;
      opacity: 0;
    }
  }

  input {
    @include visually-hidden-element;

    &:checked + label {
      &::before {
        background-color: var(--colour-radio-fill);
        border-color: var(--colour-radio-fill);
      }

      &::after {
        visibility: visible;
        opacity: 1;
      }
    }

    &:focus-visible + label::before {
      outline: 4px solid var(--colour-focus-outline);
      outline-offset: 3px;
    }

    @supports not selector(:focus-visible) {
      &:focus + label::before {
        outline: 4px solid var(--colour-focus-outline);
        outline-offset: 3px;
      }
    }
  }

  &.has-credit-card-icon label .icon {
    width: px-to-em(32px);
    height: px-to-em(32px);
    margin-right: $space-xs;
  }

  &.is-button {
    label {
      width: 100%;
      margin-left: 0px;
      padding: $space-md $space-md $space-md calc(#{$radio-size} + #{$space-md} + #{$space-xs});
      border-radius: 5px;
      border: 1px solid var(--colour-action-border);
      cursor: pointer;

      &::before,
      &::after {
        top: calc(50% - #{math.div($radio-size, 2)});
        left: $radio-size;
      }
    }

    input {
      &:checked + label {
        background: var(--colour-action-border);
      }
    }
  }
}

.checkbox input:disabled + label,
.radio input:disabled + label {
  color: var(--colour-text-disabled);
  cursor: not-allowed;

  &::before {
    opacity: 0.25;
  }
}

fieldset {
  margin-bottom: $space-sm;

  legend {
    @extend %input-label;

    font-weight: 500;
    padding-bottom: $space-sm;
  }

  & > *:last-child {
    margin-bottom: 0px;
  }

  &:not(.has-visible-legend) {
    legend {
      @include visually-hidden-element;
    }
  }

  @include input-helpers-and-errors;
}
