// Light theme

[data-theme="light"] {
  --colour-white: hsl(32, 70%, 99%);
  --colour-black: hsl(0, 0%, 12%);

  /* Brand */
  --colour-brand-yellow: hsl(49, 93%, 52%);
  --colour-brand-red: #EA482C;
  --colour-brand-red-digi: #d9381c;
  --colour-brand-cream: #FAEAD8;

  /* Shades */
  --colour-shade-0: hsl(32, 45%, 96%);
  --colour-shade-1: hsl(32, 25%, 93%);
  --colour-shade-2: hsl(32, 18%, 90%);
  --colour-shade-3: hsl(32, 15%, 88%);
  --colour-shade-4: hsl(32, 12%, 85%);
  --colour-shade-5: hsl(32, 10%, 78%);
  --colour-shade-6: hsl(32, 8%, 67%);
  --colour-shade-7: hsl(32, 8%, 56%);
  --colour-shade-8: hsl(32, 6%, 46%);
  --colour-shade-9: hsl(32, 6%, 34%);

  /* Background */
  --colour-bg-high-contrast: var(--colour-white);
  --colour-bg-lowered-contrast: var(--colour-shade-0);
  --colour-bg-elevated: var(--colour-white);
  --colour-bg-bold: var(--colour-brand-yellow);
  --colour-bg-muted: var(--colour-brand-cream);

  /* Text */
  --colour-text: var(--colour-black);
  --colour-text-invert: var(--colour-white);
  --colour-text-muted: var(--colour-shade-8);
  --colour-text-disabled: var(--colour-shade-5);
  --colour-text-brand-pop: var(--colour-brand-red);
  --colour-text-error: hsl(285, 65%, 50%);
  --colour-user-text-selection: hsla(32, 30%, 65%, 0.5);
  --colour-text-link-underline: var(--colour-brand-red);

  /* Icon */
  --colour-icon-important: var(--colour-brand-red);
  --colour-icon-hover: var(--colour-shade-3);

  /* Elevation level shadows */
  --colour-elevation-low-subtle: rgba(0, 0, 0, 0.02);
  --colour-elevation-low: rgba(0, 0, 0, 0.05);
  --colour-elevation-middle-1: rgba(57, 32, 0, 0.1);
  --colour-elevation-middle-2: rgba(57, 32, 0, 0.22);
  --colour-elevation-high: rgba(0, 0, 0, 0.25);
  --colour-elevation-high-subtle: rgba(0, 0, 0, 0.12);

  /* UI Components */
  /* - Action */
  --colour-action-fill: var(--colour-white);
  --colour-action-border: var(--colour-shade-3);
  --colour-action-hover: var(--colour-shade-0);
  --colour-action-disabled: var(--colour-shade-2);
  --colour-action-active: var(--colour-brand-yellow);
  --colour-action-text: var(--colour-text);
  --colour-action-text-invert: var(--colour-text-invert);

  /* - Primary action */
  --colour-action-primary: var(--colour-brand-yellow);
  --colour-action-primary-hover: hsl(48, 87%, 49%);
  --colour-action-primary-text: var(--colour-text);

  /* - Raised action */
  --colour-action-raised-fill: var(--colour-white);
  --colour-action-raised-hover: var(--colour-shade-0);
  --colour-action-raised-text: var(--colour-brand-red);

  /* - Ghost action */
  --colour-action-ghost-text: var(--colour-brand-red);

  /* - Badge */
  --colour-badge-text: var(--colour-text);
  --colour-badge-icon: var(--colour-brand-red);
  --colour-badge-flat-fill: var(--colour-white);
  --colour-badge-subtle-fill: var(--colour-shade-1);
  --colour-badge-strong-fill: var(--colour-shade-9);
  --colour-badge-strong-text: var(--colour-text-invert);
  --colour-badge-important-fill: var(--colour-brand-red);
  --colour-badge-important-text: var(--colour-text-invert);
  --colour-badge-success-fill: hsl(140, 70%, 76%);
  --colour-badge-success-text: var(--colour-text);
  --colour-badge-quantity-fill: var(--colour-brand-yellow);
  --colour-badge-quantity-text: var(--colour-text);
  --colour-badge-vegetarian-fill: hsl(153, 27%, 92%);
  --colour-badge-vegetarian-icon: hsl(153, 54%, 42%);
  --colour-badge-vegan-fill: hsl(87, 26%, 93%);
  --colour-badge-vegan-icon: hsl(90, 46%, 48%);
  --colour-badge-gf-fill: hsl(43, 68%, 93%);
  --colour-badge-gf-icon: hsl(43, 100%, 50%);
  --colour-badge-df-fill: hsl(218, 35%, 94%);
  --colour-badge-df-icon: hsl(215, 54%, 62%);
  --colour-badge-cn-fill: hsl(18, 100%, 95%);
  --colour-badge-cn-icon: hsl(22, 100%, 46%);
  --colour-badge-exclusive-text: var(--colour-black);

  /* - Callout */
  --colour-callout-border: var(--colour-shade-3);
  --colour-callout-border-error: var(--colour-text-error);
  --colour-callout-icon: var(--colour-brand-red);

  /* - Card */
  --colour-card-border: var(--colour-shade-1);
  --colour-card-store-closed: var(--colour-shade-1);

  /* - Chip */
  --colour-chip-hover: hsla(32, 80%, 50%, 0.15);
  --colour-chip-choice-fill: var(--colour-brand-red-digi);
  --colour-chip-choice-text: var(--colour-text);
  --colour-chip-choice-text-active: var(--colour-text-invert);
  --colour-chip-action-fill: var(--colour-brand-cream);
  --colour-chip-action-text: var(--colour-text);
  --colour-chip-action-icon: var(--colour-brand-red);
  --colour-chip-filter-icon: var(--colour-text);
  --colour-chip-filter-text: var(--colour-text);

  /* - Divider */
  --colour-divider: var(--colour-shade-4);
  --colour-divider-with-content: var(--colour-brand-cream);
  --colour-divider-with-content-strong: var(--colour-brand-red-digi);
  --colour-divider-with-content-strong-text: var(--colour-text-invert);

  /* - Text input */
  --colour-text-input-fill: var(--colour-shade-1);
  --colour-text-input-focus-border: var(--colour-shade-7);
  --colour-text-input-focus-underline: var(--colour-brand-yellow);
  --colour-text-input-muted: var(--colour-shade-7);
  --colour-text-input-error: var(--colour-text-error);
  --colour-text-input-icon: var(--colour-brand-red);

  /* - Checkbox */
  --colour-checkbox-fill: var(--colour-brand-yellow);
  --colour-checkbox-checkmark: var(--colour-black);
  --colour-checkbox-outline: var(--colour-black);

  /* - Radio */
  --colour-radio-fill: var(--colour-brand-yellow);
  --colour-radio-checkmark: var(--colour-black);
  --colour-radio-outline: var(--colour-black);

  /* - Line item */
  --colour-line-item-secondary-bg: var(--colour-shade-0);

  /* - Modal */
  --colour-modal-shadow: hsla(32, 10%, 51%, 0.8);

  /* - Notice */
  --colour-notice-fill: var(--colour-brand-yellow);
  --colour-notice-text: var(--colour-text);
  --colour-notice-raised-border: hsla(0, 0%, 12%, 0.08);
  --colour-notice-important-fill: hsl(285, 65%, 90%);
  --colour-notice-important-text: var(--colour-text);
  --colour-notice-strong-fill: var(--colour-brand-red-digi);
  --colour-notice-strong-text: var(--colour-text-invert);
  --colour-notice-deal-fill: var(--colour-brand-yellow);
  --colour-notice-deal-text: var(--colour-text);

  /* - Overlay */
  --colour-overlay-menu-shadow: hsla(32, 80%, 4%, 0.7);

  /* - Popover */
  --colour-popover-border: var(--colour-shade-4);

  /* - Link selector */
  --link-selector-hover: var(--colour-shade-1);

  /* - Tree nav */
  --colour-bottom-nav-text: var(--colour-text-muted);
  --colour-bottom-nav-icon: var(--colour-text-muted);
  --colour-bottom-nav-active: var(--colour-brand-yellow);
  --colour-bottom-nav-text-active: var(--colour-text);
  --colour-bottom-nav-icon-active: var(--colour-text);
  --colour-bottom-nav-tap-highlight: hsla(32, 77%, 91%, 0.6);

  /* - Tree nav */
  --colour-tree-nav-link-hover: var(--colour-shade-1);
  --colour-tree-nav-link-active: var(--colour-brand-yellow);
  --colour-tree-nav-disclosure-button-icon: var(--colour-shade-6);
  --colour-tree-nav-disclosure-button-hover: var(--colour-shade-0);
  --colour-tree-nav-link-text: var(--colour-text);
  --colour-tree-nav-link-text-invert: var(--colour-text);

  /* - Progress bar */
  --colour-progress-bar-bg: rgba(159, 137, 111, 0.2);
  --colour-progress-bar-fill: var(--colour-brand-red);
  --colour-progress-bar-loading-fill: var(--colour-brand-yellow);

  /* - Places autocomplete */
  --colour-places-autocomplete-dimming: hsla(32, 100%, 10%, 0.25);
  --colour-places-autocomplete-option-hover: var(--colour-shade-1);

  /* - Switch */
  --colour-switch-fill: var(--colour-shade-3);
  --colour-switch-active: var(--colour-brand-yellow);
  --colour-switch-toggle: var(--colour-white);
  --colour-switch-value-inactive: var(--colour-shade-8);
  --colour-switch-value-active: var(--colour-black);

  /* - Overflow toggle */
  --colour-overflow-toggle-icon: var(--colour-brand-red);

  /* - Tooltip */
  --colour-tooltip-fill: var(--colour-black);

  /* - Logo */
  --colour-logo-text: var(--colour-brand-red);
  --colour-logo-base: var(--colour-brand-cream);
  --colour-logo-outline: var(--colour-black);
  --colour-logo-mouth-wrinkles: var(--colour-black);
  --colour-logo-body: var(--colour-brand-yellow);
  --colour-logo-tongue: var(--colour-brand-red);
  --colour-logo-speedlines: var(--colour-black);

  /* Content */
  /* - Collapse */
  --colour-collapse-divider: var(--colour-shade-3);
  --colour-collapse-open: var(--colour-shade-0);

  /* Focus */
  --colour-focus-outline: hsla(32, 15%, 50%, 0.3);
  --colour-focus-outline-large: hsla(32, 15%, 50%, 0.3);

  /* Image */
  --colour-image-placeholder: var(--colour-shade-1);
  --colour-image-product-placeholder: linear-gradient(0deg, hsl(32, 25%, 93%) 0%, hsl(32, 25%, 93%) 100%);
  --colour-image-background-fade: hsla(32, 25%, 85%, 0.75);
  --colour-image-background-fill: white;

  /* Gradients & special fills */
  --gradient-primary: linear-gradient(155.6deg, #FF3414 50.63%, #FF6515 95.78%);
  --gradient-exclusive-partner: linear-gradient(35deg, #E7CB68 10%, #F5E384 90%);
}
