.orders-listing {
  display: flex;
  flex-direction: column;
}

.orders-listing.is-active-order {
  @include is-desktop {
    &:not(:last-of-type) {
      margin-bottom: $space-sm;
    }
  }
}

.orders-listing.is-past-order {
  background-color: var(--colour-bg-high-contrast);

  &:not(:last-of-type) {
    margin-bottom: $space-sm;
  }

  > .order-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    order: 2;

    @include horizontal-container-spacing;
    @include vertical-container-spacing;

    .title {
      font-size: $font-size-lg;
    }

    > :not(:last-child) {
      margin-bottom: $space-md;
    }

    > div > :not(:last-child) {
      margin-bottom: px-to-rem(12px);
    }
  }

  .restaurant-image {
    $image-ratio: aspect(3,5);

    order: 1;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center center;
    background-color: var(--colour-image-placeholder);
    background-image: url("../../../images/restaurant-card-placeholder.svg");

    &::after {
      content: "";
      height: 0;
      width: 0;
      display: block;
      padding-bottom: calc(#{$image-ratio} * 100%);
    }
  }

  @include is-desktop {
    flex-direction: initial;

    .order-details {
      flex-direction: initial;
      align-items: center;
      justify-content: space-between;
      order: 1;
      margin-bottom: 0px;

      .title {
        font-size: $font-size-xl;
      }

      > :not(:last-child) {
        margin-bottom: 0px;
      }
    }

    .restaurant-image {
      order: 2;
      max-width: 300px;
    }
  }
}
