.breadcrumbs {
  display: flex;
  align-items: center;
  gap: $space-sm;

  > .icon {
    font-size: px-to-rem(10px);
  }

  > a, > span {
    text-wrap: nowrap;
    padding-block: px-to-rem(7px)
  }
}
