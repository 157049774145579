.option-bar {
  display: flex;
  align-items: center;
  width: 100%;
  gap: $space-sm;

  > svg.icon {
    font-size: $font-size-xs;
  }

  @include is-tablet {
    gap: $space-md;

    > svg.icon {
      font-size: $font-size-sm;
    }
  }

  @include is-desktop {
    > svg.icon {
      font-size: $font-size-md;
    }
  }

  &.is-tiny {
    gap: $space-xs;

    > svg.icon {
      font-size: px-to-rem(14px);
    }
  }
}
