.marquee {
  $animation-length: 75s;

  width: 100%;
  height: 19vw;
  overflow: hidden;
  white-space: nowrap;
  pointer-events: none;
  user-select: none;

  @include is-tablet {
    height: 15vw;
  }

  @include is-desktop {
    height: 13vw;
  }

  > svg {
    height: 100%;
    animation: $animation-length linear infinite horizontal-slide;
  }

  // Stagger / offset consecutive marquees
  & + & svg:first-of-type {
    margin-left: -100vw;
  }

  & + & + & svg:first-of-type {
    margin-left: -200vw;
  }


  @media (prefers-reduced-motion) {
    height: auto !important;

    > svg {
      height: auto;
      width: 110%;
      animation-play-state: paused;
      transform: translateX(-55%) !important;
    }
  }

  &.is-new {
    // Refactor into .marquee after Sept 12 2023
    height: 11vw;

    @include is-tablet {
      height: 11.5vw;
    }

    @include is-desktop {
      height: 12vw;
    }
  }
}

@keyframes horizontal-slide {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}
