// There is no default chip style, must be used with is-choice or is-action
// this class can be exposed later if needed
%chip {
  display: inline-flex;
  align-items: center;
  border-radius: 2em;
  text-decoration: none;

  @include has-mouse-pointer {
    &:hover {
      text-decoration: none;
      box-shadow: inset 0 0 1em 1em var(--colour-chip-hover);
    }
  }
}

.chip.is-choice {
  @extend %chip;

  font-size: $font-size-md;

  > label {
    cursor: pointer;
    color: var(--colour-chip-choice-text);
    border-radius: inherit;
    padding: px-to-rem(8px) px-to-rem(16px);
    line-height: 1;
    white-space: nowrap;

    @include remove-default-tap-highlights;
}

  > input[type="radio"] {
    @include visually-hidden-element;
  }

  > input[type="radio"]:checked + label {
    background-color: var(--colour-chip-choice-fill);
    color: var(--colour-chip-choice-text-active);
  }

  > input[type="radio"]:active + label {
    box-shadow: inset 0 0 1em 1em hsla(0, 0%, 50%, 0.45);
  }

  @include has-mouse-pointer {
    input[type="radio"]:focus-visible + label {
      outline: 4px solid var(--colour-focus-outline);
      outline-offset: 3px;
    }

    @supports not selector(:focus-visible) {
      input[type="radio"]:focus + label {
        outline: 4px solid var(--colour-focus-outline);
        outline-offset: 3px;
      }
    }
  }
}

.chip.is-action {
  @extend %chip;

  font-size: $font-size-xs;
  padding: px-to-rem(8px) px-to-rem(12px);
  background-color: var(--colour-chip-action-fill);
  color: var(--colour-chip-action-text);
  line-height: 1;

  :not(:last-child) {
    margin-right: px-to-em(8px);
  }

  > .icon {
    color: var(--colour-chip-action-icon);
  }

  @include is-touch-device {
    cursor: default;
    user-select: none;
  }

  &:focus-visible {
    outline: 4px solid var(--colour-focus-outline);
    outline-offset: 3px;
  }
  
  @supports not selector(:focus-visible) {
    &:focus {
      outline: 4px solid var(--colour-focus-outline);
      outline-offset: 3px;
    }
  }
}

.chip.is-filter,
.chip.is-filter-illustrated {
  display: inline-flex;
  position: relative;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    padding: px-to-rem(10px) px-to-rem(20px);
    border: 1px solid var(--colour-action-border);
    border-radius: 6px;
    line-height: 1;
    user-select: none;

    @include is-tablet {
      padding: px-to-rem(12px) $space-md;
    }

    @include has-mouse-pointer {
      cursor: pointer;

      &:hover {
        background-color: var(--colour-action-hover);
      }
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      right: $space-xs;
      height: px-to-rem(16px);
      width: px-to-rem(16px);
    }

    &::before {
      background-color: hsla(360, 100%, 16%, 0.38);

      @include round-radius;
    }

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 8 8'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23fff' d='M.146 7.146a.5.5 0 0 0 .708.707L3.94 4.766a.083.083 0 0 1 .118 0l3.087 3.088a.5.5 0 0 0 .708-.707L4.767 4.06a.083.083 0 0 1 0-.118L7.854.853a.5.5 0 1 0-.708-.707L4.06 3.233a.083.083 0 0 1-.118 0L.854.146a.5.5 0 1 0-.708.707l3.087 3.088a.083.083 0 0 1 0 .118L.146 7.146Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h8v8H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      transform: scale(0.5);
    }
  }

  input {
    &:checked ~ label {
      background-color: var(--colour-action-active);
      border-color: var(--colour-action-active);
      color: var(--colour-chip-filter-text);
    }

    &:not(:checked) ~ label::before,
    &:not(:checked) ~ label::after {
      @include hidden-element-can-animate;
    }

    @include visually-hidden-element;

    @include has-mouse-pointer {
      &:focus-visible + label {
        border-color: transparent;
        outline: 4px solid var(--colour-focus-outline);
        outline-offset: 3px;
      }

      @supports not selector(:focus-visible) {
        &:focus + label {
          outline: 4px solid var(--colour-focus-outline);
          outline-offset: 3px;
        }
      }
    }
  }

  // Style clear filter button to have the same focus style as a filter chip
  @at-root button#{&} {
    @include has-mouse-pointer {
      outline: none;

      &:focus-visible > label {
        border-color: transparent;
        outline: 4px solid var(--colour-focus-outline);
        outline-offset: 3px;
      }

      @supports not selector(:focus-visible) {
        &:focus > label {
          outline: 4px solid var(--colour-focus-outline);
          outline-offset: 3px;
        }
      }
    }
  }
}

.chip.is-filter {
  label {
    > span {
      transition: transform 0.07s ease-in;
    }

    &::before,
    &::after {
      transition: opacity 0.075s ease-in, visibility 0.075s ease-in;
    }
  }

  input:checked ~ label > span {
    transform: translateX(px-to-rem(-10px));
  }
}

.chip.is-filter-illustrated {
  label {
    width: px-to-rem(100px);
    font-size: px-to-rem(14px);
    flex-direction: column;
    white-space: nowrap;

    img,
    .image {
      width: px-to-rem(54px);
      height: px-to-rem(54px);
    }

    &::before,
    &::after {
      top: px-to-rem(8px);
      left: px-to-rem(8px);
    }

    @include is-tablet {
      width: px-to-rem(128px);
      font-size: px-to-rem(16px);

      img,
      .image {
        width: px-to-rem(80px);
        height: px-to-rem(80px);
      }
    }
  }

  &.is-reset {
    label::before,
    label::after {
      content: unset;
    }

    .image {
      color: var(--colour-chip-filter-icon);
      display: flex;
      align-items: center;
      justify-content: center;

      > .icon {
        width: px-to-rem(16px);
        height: px-to-rem(16px);
      }
    }
  }
}
