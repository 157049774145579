.device-frame {
  display: inline-block;
  position: relative;

  > img {
    max-width: 180px;

    @include is-tablet {
      max-width: 250px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../../images/device-frame.png");
    pointer-events: none;
  }
}
