.overlay {
  width: 100%;
  display: flex;
  position: fixed;
  top: 0px;
  z-index: $layer-highest;
  transform: translate(0px, -100%);
  box-shadow: 0px 0px 0px 100vh transparent;
  visibility: hidden;
  transition: all 0.28s cubic-bezier(.79,.14,.15,.86);

  .card {
    transition: all 0.28s cubic-bezier(.79,.14,.15,.86);
  }

  &:not(.is-menu, .is-sidebar) .overlay-header {
    @include vertical-container-spacing('margin-bottom');
  }

  &.is-active {
    visibility: visible;
    transform: translate(0px, 0px);
    box-shadow:  0px 0px 0px 100vh var(--colour-overlay-menu-shadow);
  }
}

.overlay:where(.is-menu, .is-sidebar) {
  height: 100%;
  right: 0;
  transform: translate(100%, 0px);
  box-shadow: 0px 0px 0px 100vw transparent;

  .overlay-header {
    @include extra-desktop-space-vertical($header-extra-desktop-space-vertical);
  }

  nav {
    overflow-y: auto;
    overscroll-behavior: none;
  }

  .is-nav-footer {
    flex-grow: 1;

    > .shielded-button > a {
      display: flex;
    }

    @include is-tablet {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: none;

      > li {
        display: flex;
        align-items: center;

        &:not(:last-of-type):not(:nth-last-of-type(2)):after {
          content: "·";
          display: inline;
          margin-left: $space-xs;
        }

        &.shielded-button {
          margin-left: auto;

          > a {
            padding-left: px-to-rem(10px);
            padding-right: px-to-rem(10px);
          }
        }
      }
    }

    &:last-child {
      @include vertical-container-spacing ('padding-bottom');
    }
  }

  &.is-active {
    transform: translate(0px, 0px);
  }

  @include is-tablet {
    max-width: px-to-rem(415px);
  }

  @include is-desktop-wide {
    max-width: px-to-rem(490px);
  }

  @include is-desktop-super-wide {
    max-width: px-to-rem(550px);
  }
}

.overlay.is-menu.is-active {
  box-shadow: 0px 0px 0px 100vw var(--colour-overlay-menu-shadow);
}

.overlay.is-sidebar {
  @include elevation-high-subtle;
}

.overlay.is-modal {
  @include is-tablet {
    height: 100%;
    align-items: center;
    justify-content: center;
    transform: none;

    .card {
      width: auto;
      height: auto;
      min-width: 600px;
    }

    .overlay-header {
      display: none;
    }

    @include modal-initial-state;
    @include modal-background;

    &.is-active {
      box-shadow: none;

      @include modal-active;
    }
  }
}
