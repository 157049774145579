.divider:not(.has-content),
hr {
  margin: $space-md 0px;
  height: 1px;
  box-sizing: content-box;
  @include divider-line;
}

.divider.has-content {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: px-to-rem(12px) 0px;
  background-color: var(--colour-divider-with-content);
  row-gap: $space-xs;

  @include horizontal-container-spacing;
  @include extra-desktop-space-horizontal;
  @include choice-strip-end-space;

  > * {
    margin: 0;
  }

  .label {
    font-size: $font-size-md;
    line-height: px-to-rem(28px);
  }

  &.has-high-contrast {
    background-color: var(--colour-bg-high-contrast);

    &-above-tablet {
      @include is-tablet {
        background-color: var(--colour-bg-high-contrast);
      }
    }

    &-above-desktop {
      @include is-desktop {
        background-color: var(--colour-bg-high-contrast);
      }
    }
  }

  &.has-transparent-background {
    background: none;
    pointer-events: none;

    > * {
      pointer-events: auto;
    }
  }

  &.is-strong {
    background-color: var(--colour-divider-with-content-strong);
    color: var(--colour-divider-with-content-strong-text);
  }
}
