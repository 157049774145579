:where(img, svg).responsive-image {
  max-width: 100%;
  object-fit: cover;

  &[style*="--object-position"] {
    object-position: var(--object-position);
  }

  &[style*="--aspect-ratio"] {
    aspect-ratio: var(--aspect-ratio);
  }

  &[style*="--aspect-ratio-tablet"] {
    @include is-tablet {
      aspect-ratio: var(--aspect-ratio-tablet);
    }
  }

  &[style*="--aspect-ratio-desktop"] {
    @include is-desktop {
      aspect-ratio: var(--aspect-ratio-desktop);
    }
  }

  &[style*="--aspect-ratio-desktop-wide"] {
    @include is-desktop-wide {
      aspect-ratio: var(--aspect-ratio-desktop-wide);
    }
  }

  &[style*="--aspect-ratio-desktop-super-wide"] {
    @include is-desktop-super-wide {
      aspect-ratio: var(--aspect-ratio-desktop-super-wide);
    }
  }
}

:where(img, svg).is-rounded {
  border-radius: 12px;
}

:where(img, svg, .thumbnail-image).is-circle {
  border-radius: 50%;
}

:where(img, svg).is-full-width {
  width: 100%;
}

.thumbnail-image {
  width: 40px;
  height: 40px;
  background-size: cover;
}
