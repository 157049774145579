// Depends on divider has-content in divider.scss for the <summary> disclosure widget style
details.required-choice {
  $transition: 0.2s all ease-out;

  box-sizing: border-box;
  transition: $transition;

  // Hide the default summary disclosure widget
  summary {
    list-style: none;

    &::-webkit-details-marker {
      display: none;
    }
  }

  summary.divider.has-content {
    // Modify some things about how a divider with content works inside the summary widget
    cursor: pointer;
    justify-content: space-between;
    transition: $transition;

    .choice-summary {
      min-width: 0;
    }

    .choice-label {
      font-size: $font-size-md;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: $transition;
    }

    .choice-value {
      font-size: $font-size-sm;
      height: $font-size-sm * 1.5;
      line-height: $font-size-sm * 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: $transition;

      @include is-tablet {
        font-size: $font-size-lg;
        height: $font-size-lg * 1.5;
        line-height: $font-size-lg * 1.5;
      }
    }

    .choice-expander {
      display: flex;
      align-items: center;
      margin-left: $space-sm;
      gap: px-to-rem(12px);

      .icon {
        transition: $transition;
      }
    }
  }

  .required-label {
    font-size: $font-size-xs;
  }
}

// State when choices are hidden
details.required-choice:not([open]) {
  @include vertical-container-spacing('padding-bottom');
  @include horizontal-container-spacing;
  @include extra-desktop-space-horizontal;

  summary.divider.has-content {
    border-radius: 5px;
    padding-left: $space-sm;
    padding-right: $space-sm;

    @include is-tablet {
      padding-left: $space-md;
      padding-right: $space-md;
    }

    .choice-label {
      font-size: $font-size-xs;
    }
  }

  &:not(.is-valid) {
    summary.divider.has-content {
      background-color: var(--colour-divider-with-content-strong);
      color: var(--colour-divider-with-content-strong-text);
    }
  }

  &.is-valid {
    summary.divider.has-content .choice-label {
      color: var(--colour-text-muted);
    }

    .required-label {
      @include completely-hidden-element;
    }
  }
}

// State when choices are visible
details.required-choice[open] {
  summary.divider.has-content {
    background-color: var(--colour-divider-with-content-strong);
    color: var(--colour-divider-with-content-strong-text);

    .choice-label {
      line-height: px-to-rem(28px);
    }

    .choice-value {
      height: 0px;

      @include hidden-element-can-animate;
    }

    .icon {
      transform: rotate(-180deg);
    }
  }
}
