nav.list-nav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: $space-xs;

  @include subtle-scrollbars;

  @include is-touch-device {
    @include hide-scrollbars;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: $space-xs;
    margin: 0px;
    padding: 0px;

    li {
      list-style: none;

      a {
        width: 100%;
        display: inline-block;
        font-size: $font-size-sm;
        padding: px-to-rem(8px) 0px;
      }
    }

    & + ul {
      margin-top: $space-xs;
    }

    &.is-nav-primary {
      li a {
        font-size: $font-size-lg;
        padding: px-to-rem(10px) 0px;
      }
    }
  }

  hr {
    @include is-desktop-wide {
      margin: $space-lg 0px;
    }
  }
}
