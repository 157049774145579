.popover-sheet {
  $caret-size: px-to-rem(10px);
  $gap: $space-xs;

  max-width: 100%;
  flex-basis: min-content;

  > .surface {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 6px 6px 0 0;
    background-color: var(--colour-bg-high-contrast);
    transition: transform 0.25s cubic-bezier(.79,.14,.15,.86);
    z-index: $layer-highest;
    max-height: calc(100vh - #{px-to-rem(48px)});
    overflow: hidden;

    > .content {
      max-height: calc(100vh - #{px-to-rem(48px)});
      overflow-y: auto;
      overscroll-behavior: none;

      > section,
      > .container {
        padding: $mobile-container-spacing;
      }

      @include subtle-scrollbars;

      @include is-touch-device {
        @include hide-scrollbars;
      }
    }
  }

  > .shim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--colour-overlay-menu-shadow);
    transition: all 0.25s cubic-bezier(.79,.14,.15,.86);
    z-index: $layer-highest;
  }

  &:not(:where(.is-active, .is-active-on-focus:focus-within)) > .surface {
    transform: translateY(100%);
  }

  &:not(:where(.is-active, .is-active-on-focus:focus-within)) > .shim {
    @include hidden-element-can-animate;
  }

  @include is-desktop {
    &:not(.is-floating-above-desktop) {
      display: flex;
      justify-content: center;
      position: relative;

      > .shim {
        display: none;
      }

      > .surface {
        position: absolute;
        left: auto;
        top: calc(100% + #{$gap} + #{$caret-size});
        width: max-content;
        height: max-content;
        min-width: px-to-rem(360px);
        max-width: px-to-rem(400px);
        border: 1px solid var(--colour-popover-border);
        border-radius: 6px;
        transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out, visibility 0.1s ease-in-out;

        @include elevation-high-subtle;

        > .content {
          max-height: 75vh
        }

        &::after {
          content: "";
          align-self: center;
          position: absolute;
          bottom: calc(100% - #{math.div($caret-size, 2)});
          width: $caret-size;
          height: $caret-size;
          transform: rotate(45deg);
          background-color: var(--colour-bg-high-contrast);
          border-top: 1px solid #DADADA;
          border-left: 1px solid #DADADA;
          border-radius: 2px 0 0 0;
          box-sizing: content-box;
        }
      }

      &:not(:where(.is-active, .is-active-on-focus:focus-within)) > .surface {
        transform: none;

        @include hidden-element-can-animate;
      }
    }
  }
}

.popover-sheet.is-floating-above-desktop {
  @include is-desktop {
    > .shim {
      display: none;
    }

    > .surface {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.95);
      width: auto;
      height: fit-content;
      min-width: px-to-rem(460px);
      max-width: px-to-rem(800px);
      max-height: calc(100vh - #{px-to-rem($space-xl)} * 2);
      border-radius: 6px;
      transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out, visibility 0.1s ease-in-out;

      @include elevation-high-subtle;
    }

    &.is-active {
      > .surface {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &:not(.is-active) > .surface {
      @include hidden-element-can-animate;
    }
  }

  &.is-modal {
    > .shim {
      display: unset;
    }
  }
}

.popover-sheet.is-full-height-below-mobile {
  > .surface {
    height: 100%;

    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    @include is-desktop {
      height: max-content;

      .content {
        height: initial;
        display: initial;
        flex-direction: initial;
      }
    }
  }
}

.popover-sheet.is-full-screen-below-mobile {
  > .surface {
    height: 100%;
    max-height: unset;

    @include is-not-desktop {
      border-radius: unset;

      .content {
        height: 100%;
        max-height: unset;
        display: flex;
        flex-direction: column;
      }
    }

    @include is-desktop {
      height: max-content;
    }
  }
}

.popover-sheet.has-fulfilment-options {
  @include is-desktop {
    > .surface {
      min-width: px-to-rem(420px);
      max-width: px-to-rem(460px);
    }
  }

  .content {
    max-height: unset;
  }
}
