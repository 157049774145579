// Spacing
$space-xs: px-to-rem(8px);
$space-sm: px-to-rem(16px);
$space-md: px-to-rem(24px);
$space-lg: px-to-rem(32px);
$space-xl: px-to-rem(40px);

// Container padding and spacing
$mobile-container-spacing: $space-md;
$tablet-container-spacing: $space-lg;
$desktop-container-spacing: $space-lg;
$desktop-wide-container-spacing: $space-xl;
$extra-desktop-container-spacing: px-to-rem(70px); // Extra spacing on desktop for the main layout containers

// Layers
$layer-highest: 9;
$layer-high: 7;
$layer-middle: 5;
$layer-low: 3;
$layer-lowest: 1;
$layer-background: -1;

// Buttons
$button-icon-only-size: px-to-rem(36px);
$floating-back-button-spacing-y: px-to-rem(12px);

// Header
$logo-height: px-to-rem(30px);
$logo-width: px-to-rem(178px);
$header-extra-desktop-space-vertical: 50px;

// Offset helpers
$floating-back-button-offset: $button-icon-only-size + ($floating-back-button-spacing-y * 2); // equal to back button size including it's padding
