// Allow base font size to be controlled dynamically with an offset
// :root {
//   --de-ui-font-size-offset: 0px;
//   font-size: calc(100% + var(--de-ui-font-size-offset));
// }

// Default font sizes
$font-size-xs: px-to-rem(16px) !default;
$font-size-sm: px-to-rem(18px) !default;
$font-size-md: px-to-rem(20px) !default;
$font-size-lg: px-to-rem(22px) !default;
$font-size-xl: px-to-rem(28px) !default;
$font-size-xxl: px-to-rem(36px) !default;
$font-size-xxxl: px-to-rem(46px) !default;

// Text style mixins
@mixin tiny-text {
  font-size: $font-size-xs;
}

@mixin muted-text($important: null) {
  color: var(--colour-text-muted) $important;
}

@mixin tiny-muted-text {
  @include tiny-text;
  @include muted-text;

  @include is-tablet {
    font-size: $font-size-sm;
  }
}

@mixin bold-text {
  font-weight: 500;
}

@mixin error-text {
  color: var(--colour-text-error);
}

// Define JT Olifer Bold for use in specific display headings
@font-face {
  font-family: "JT Olifer";
  src: url("../../fonts/JTOlifer-Bold.woff2");
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-feature-settings: normal;
}

// Define custom sized local font fallbacks for JT Olifer Bold to avoid cumulative layout shifting during load
@font-face {
  font-family: "Arial black (Display heading)";
  src: local("Arial black");
  font-style: bold;
  size-adjust: 86%;
  ascent-override: 83%;
  descent-override: 22%;
}

// Define Founders Grotesk as the Delivereasy body typeface
// Founders Grotesk is quite small and it's baseline sits low inside it's bounding box
// (giving the appearance of it sitting low in UI elements like buttons). This defines
// Ascent and Descent override values to make it more centered so that we don't need
// lots of padding hacks all through the rest of the codebase.
$founders-grotesk-ascent: 84%;
$founders-grotesk-descent: 16%;

@font-face {
  font-family: "Founders Grotesk";
  src: url("../../fonts/founders-grotesk-regular.woff2");
  font-weight: normal;

  ascent-override: $founders-grotesk-ascent;
  descent-override: $founders-grotesk-descent;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("../../fonts/founders-grotesk-medium.woff2");
  font-weight: 500;

  ascent-override: $founders-grotesk-ascent;
  descent-override: $founders-grotesk-descent;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("../../fonts/founders-grotesk-bold.woff2");
  font-weight: 600;

  ascent-override: $founders-grotesk-ascent;
  descent-override: $founders-grotesk-descent;
}

$display-family-stack: "JT Olifer", "Arial black (Display heading)", system-ui, sans-serif;

body {
  font-family: "Founders Grotesk", sans-serif;
  color: var(--colour-text);
  font-size: $font-size-sm;
}

// Headings
h1, h2, h3, h4, h5 {
  font-weight: normal;
}

h1 {
  font-size: $font-size-xxxl;
  margin: $space-md 0;
}

h2 {
  font-size: $font-size-xxl;
  margin: $space-sm 0;

  @include is-tablet {
    font-size: $font-size-xxxl;
  }
}

h3 {
  font-size: $font-size-xl;
  margin: $space-sm 0;

  @include is-tablet {
    font-size: $font-size-xxl;
  }
}

h4 {
  font-size: $font-size-lg;
  margin: $space-sm 0;

  @include is-tablet {
    font-size: $font-size-xl;
  }
}

h5 {
  font-size: $font-size-md;
  margin: $space-xs 0;
  font-weight: 500;
}

p {
  max-width: 65ch;
  line-height: 1.35;
  margin-top: 0;

  &.has-short-line-length {
    max-width: 40ch;
  }
}

ul {
  padding-inline-start: 1em;
  display: flex;
  flex-direction: column;
  gap: $space-sm;
  line-height: 1.4;
}

ul.has-emoji-markers {
  $list-marker-margin: 1em;

  list-style: none;
  padding-inline-start: 1em + $list-marker-margin;

  li {
    position: relative;

    &::before {
      content: attr(data-marker);
      position: absolute;
      right: calc(100% + #{$list-marker-margin});
    }
  }
}

b {
  font-weight: 500;
}

%underline-link {
  text-decoration: none;
  border-bottom: 1px solid var(--colour-text-link-underline);

  @include has-mouse-pointer {
    &:hover {
      border-color: var(--color-text);
      text-decoration: none;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;

  @include has-mouse-pointer {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &.has-underline {
    @extend %underline-link;

    &.is-normal-underline {
      border-color: var(--colour-text);
    }

    &.is-muted-text {
      border-color: var(--colour-text-muted);
    }
  }
}

.is-display-heading {
  font-family: $display-family-stack;
  color: var(--colour-text-brand-pop);
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: bold;
  overflow-wrap: break-word;
}

.is-hero-display-heading {
  font-family: $display-family-stack;
  text-transform: uppercase;
  font-size: 6.8vmin;
  max-width: 20ch;
  font-weight: normal;
  line-height: 1;
  margin-bottom: $space-lg;
  color: var(--colour-text-brand-pop);

  @include is-tablet {
    margin-bottom: $space-xl;
  }

  :not(aside.column) & {
    @include is-tablet {
      font-size: 5.8vmin;
    }

    section.cover.is-two-columns & {
      @include is-desktop {
        font-size: 3.2vmax;
      }
    }
  }

  aside.column & {
    @include is-desktop {
      font-size: 2.6vw;
    }
  }
}

.category-section-heading {
  font-size: px-to-rem(22px);

  @include is-tablet {
    font-size: $font-size-xxl;
  }
}

// Style defaults for fomatting markdown generated HTML
.content-formatted {
  a {
    @extend %underline-link;
  }
}
