@use "sass:math";

.card {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--colour-bg-high-contrast);

  @include remove-hover-underline;

  > a {
    @include remove-hover-underline;
  }

  .title {
    @include is-tablet {
      font-size: $font-size-xxl;
    }
  }
}

.fixed-bottom-element > .card {
  background: linear-gradient(rgba(255, 255, 255, 0), var(--colour-bg-high-contrast));
  clip-path: none;
  display: block;

  @include vertical-container-spacing;
  @include horizontal-container-spacing;

  > * {
    background-color: var(--colour-bg-high-contrast);
    border-radius: 6px;
    overflow: hidden;

    @include elevation-high;
  }

  > a {
    display: block;

    @include remove-hover-underline;
  }

  @include is-tablet {
    padding: 0px;

    > * {
      border-radius: 0px;
    }
  }
}

.card.is-restaurant {
  $image-ratio: aspect(3,5);

  @extend .card;

  background-color: transparent;
  position: relative;

  > * {
      margin-bottom: $space-sm;
  }

  .title {
    font-size: $font-size-md;
    display: flex;
    align-items: center;
    gap: $space-xs;

    @include is-tablet {
      font-size: $font-size-xl;
    }

    .exclusive-gem {
      display: flex;

      @include animate-subtle;

      &:hover {
        background-color: var(--colour-icon-hover);
        box-shadow: 0px 0px 0px 4px var(--colour-icon-hover);

        @include round-radius;
      }
    }
  }

  .image {
    border-radius: 6px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center center;
    background-color: var(--colour-image-placeholder);
    background-image: url("../../../images/restaurant-card-placeholder.svg");
    overflow: hidden;

    &.is-loading {
      background-image: url("../../../images/restaurant-card-loading.svg");
    }

    &::after {
        content: "";
        height: 0;
        width: 0;
        display: block;
        padding-bottom: calc(#{$image-ratio} * 100%);
    }

    > .badge {
        position: absolute;
        top: $space-xs;
        right: $space-xs;

        &.is-exclusive-partner {
          left: $space-xs;
          right: initial;
        }
    }
  }

  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;

    @include tiny-muted-text;

    .choice-strip > & {
      -webkit-line-clamp: 2;
    }
  }

  .attributes {
    display: flex;
    flex-wrap: wrap;
    gap: $space-sm;
  }

  > .anchor {
    position: absolute;
    top: -(math.div(100vh, 3));
    left: 0px;
  }

  &.is-closed {
    .title {
      opacity: 0.6;
    }

    .attributes {
      filter: saturate(0%);
    }

    .image {
      filter: saturate(35%);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.8;
        background-color: var(--colour-card-store-closed);
      }

      &:not([style*="background-image"])::before {
        opacity: 0.4;
      }
    }
  }
}

%raised-surface {
  clip-path: none;
  border-radius: 12px;
  width: auto;
  color: var( --colour-text);
  border: 1px solid var(--colour-card-border);

  @include elevation-low;

  @include is-desktop {
    border-radius: 16px;
  }
}

.card.is-raised {
  @extend %raised-surface;
}

.card.is-info {
  @extend %raised-surface;

  align-items: center;
  flex-grow: 1;
  gap: $space-md;

  @include vertical-container-spacing;
  @include horizontal-container-spacing;

  > * {
    margin: 0;
  }

  p {
    max-width: 32ch;

    @include tiny-text;

    @include is-tablet {
      font-size: $font-size-sm;
    }
  }

  &.has-full-width-text p {
    max-width: initial;
  }
}
