.icon {
  flex-shrink: 0;

  &.has-fill-important {
    color: var(--colour-icon-important);
  }
}

.streamline-icon,
.custom-icon > svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
  line-height: 1em;
}

.streamline-icon,
.custom-icon {
  &.is-medium {
    font-size: $font-size-xl !important;
  }

  &.is-large {
    font-size: $font-size-xxxl !important;
  }

  &.is-hero {
    font-size: px-to-rem(80px) !important;
  }
}

.custom-icon {
  display: inline-block;

  > svg {
    display: block;
  }

  // The scooter animation is a weird shape, so this increases it's size everywhere
  // to make it's perceptual size correct relative to all the other icons
  svg.animated-scooter {
    font-size: 1.25em;
  }
}
