header {
  background-color: var(--colour-bg-high-contrast);

  @include vertical-container-spacing;
  @include extra-desktop-space-vertical($header-extra-desktop-space-vertical);

  @include is-not-tablet {
    // padding-bottom: $space-sm;
  }

  > .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: px-to-rem(20px);

    > .logo {
      order: -1;
    }

    > .mini-tagline {
      margin-left: $space-md;
      font-size: $font-size-sm;

      b {
        color: var(--colour-text-brand-pop)
      }

      @include is-desktop-wide {
        margin-left: $space-lg;
        font-size: $font-size-md;
      }
    }
  }

  .nav.has-title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 0;

    > *:not(:last-child) {
      margin-right: $space-sm;

      @include is-tablet {
        margin-right: $space-md;
      }
    }

    > h1 {
      order: 2;
      margin: 0px;
      font-size: $font-size-xl;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.25;

      @include is-tablet {
        font-size: $font-size-xxl;
      }
    }

    .popover-sheet {
      order: 3;
    }

    .vertical-bar {
      width: 1px;
      align-self: stretch;
      background-color: var(--colour-divider);
    }

    .logo {
      display: none;

      @include is-tablet {
        display: inline-flex;
      }
    }
  }

  .popover-sheet.has-fulfilment-options {
    order: 6;
    width: 100%;

    @include is-tablet {
      width: auto;
      order: 3;
      margin-left: $tablet-container-spacing;
    }

    @include is-desktop {
      margin-left: $extra-desktop-container-spacing;
    }
  }

  .menu-and-search {
    order: 4;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: min-content;
    flex-grow: 1;

    @include is-desktop {
      gap: $space-md;
      margin-left: $space-md;
    }

    .popover-sheet {
      max-width: 35ch;
    }
  }

  .signin-button {
    @include is-not-desktop-wide {
      display: none;
    }
  }


  &.is-sticky {
    position: sticky;
    top: 0px;
    z-index: $layer-high;
  }
}

header.is-muted {
  background-color: var(--colour-bg-muted);
}

header.has-divider {
  border-bottom: 1px solid var(--colour-divider);
}
