.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > svg {
    width: px-to-rem(100px);
    height: px-to-rem(100px);
    margin-bottom: $space-xl;
  }

  h1 {
    font-size: $font-size-xxl;
    margin-top: 0px;
    margin-bottom: $space-sm;
  }

  p {
    margin-bottom: $space-md;
  }
}