
section.cover {
  // The cover height uses a dynamic viewport unit based calc to make sure auto-hiding mobile url
  // bars are taken into account. Falls back to a CSS var & polyfill based calc in old browsers.

  --100dvh: var(--1dvh, 1vh) * 100; // Polyfill based dvh value

  display: flex;
  padding-top: 0px;
  position: relative;
  min-height: calc(var(--100dvh) - #{$button-icon-only-size} - #{$space-md * 2}); // Fallback value
  min-height: calc(100dvh - #{$button-icon-only-size} - #{$space-md * 2});

  @include is-tablet {
    min-height: calc(var(--100dvh) - #{$button-icon-only-size} - #{$space-lg * 2}); // Fallback value
    min-height: calc(100dvh - #{$button-icon-only-size} - #{$space-lg * 2});
  }

  @include is-desktop-wide {
    min-height: calc(var(--100dvh) - #{$button-icon-only-size} - #{$header-extra-desktop-space-vertical * 2}); // Fallback value
    min-height: calc(100dvh - #{$button-icon-only-size} - #{$header-extra-desktop-space-vertical * 2});
  }

  .cover-content {
    display: flex;
    flex-direction: column;
  }

  .cover-image {
    display: flex;
    flex-direction: column;
    background-image: var(--cover-image-1x);
    background-repeat: no-repeat;

    @include vertical-container-spacing;
    @include horizontal-container-spacing;

    @include extra-desktop-space-vertical($header-extra-desktop-space-vertical);
    @include extra-desktop-space-horizontal;

    @include is-desktop {
      background-image: var(--cover-image-2x);
      background-image: image-set(
        var(--cover-image-1x) 1x,
        var(--cover-image-2x) 2x
      );
      background-image: -webkit-image-set(
        var(--cover-image-1x) 1x,
        var(--cover-image-2x) 2x
      );
    }

    .menu-button {
      position: fixed;
      display: none;
    }
  }

  &.is-background {
    .cover-image {
      $desktop-ratio: aspect(9,16);
      $tablet-ratio: aspect(4,5);
      $mobile-ratio: aspect(1,1);

      background-size: cover;
      background-position: right bottom;
      height: $mobile-ratio * 100vw;
      z-index: $layer-background;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      @include is-tablet {
        height: $tablet-ratio * 100vw;
      }

      @include is-desktop {
        background-size: contain;
        height: $desktop-ratio * 100vw;
        max-height: calc(var(--100dvh) - #{$button-icon-only-size} - #{$space-lg * 2}); // Fallback value
        max-height: calc(100dvh - #{$button-icon-only-size} - #{$space-lg * 2});
      }

      @include is-desktop-wide {
        max-height: calc(var(--100dvh) - #{$button-icon-only-size} - #{$header-extra-desktop-space-vertical * 2}); // Fallback value
        max-height: calc(100dvh - #{$button-icon-only-size} - #{$header-extra-desktop-space-vertical * 2});
      }
    }
  }

  &.is-two-columns {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    @include is-desktop {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
    }

    .cover-image {
      background-size: cover;
      background-position: 50% 25%;
      margin-bottom: -$space-md;
      clip-path: inset(0%);

      @include is-tablet {
        margin-bottom: -$space-lg;
      }

      @include is-desktop {
        background-position: 50% 20%;
        position: absolute;
        left: 50%;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: $layer-high;
        height: calc(var(--100dvh)); // Fallback value
        height: 100dvh;
        margin-top: -$button-icon-only-size - $space-lg * 2;
      }

      @include is-desktop-wide {
        margin-top: calc(-#{$button-icon-only-size} - #{$header-extra-desktop-space-vertical * 2});
      }

      .menu-button {
        @include is-desktop {
          display: flex;
          z-index: $layer-high;
        }
      }
    }
  }
}
