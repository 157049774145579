.logo {
  display: inline-block;
  text-decoration: none;
  height: $logo-height;
  width: $logo-width;
  max-width: 100%;

  > img,
  > svg {
    height: $logo-height;
    width: $logo-width;
    max-width: 100%;
  }

  > svg path {
    shape-rendering: geometricPrecision;
  }

  > svg.delivereasy-horizontal-rgb-optical-cut {
    > .logo-text {
      fill: var(--colour-logo-text);
    }

    > .logo-base {
      fill: var(--colour-logo-base);
    }

    > .logo-outline {
      fill: var(--colour-logo-outline);
    }

    > .logo-mouth-wrinkles {
      fill: var(--colour-logo-mouth-wrinkles);
    }

    > .logo-body {
      fill: var(--colour-logo-body);
    }

    > .logo-tongue {
      fill: var(--colour-logo-tongue);
    }

    > .logo-speedlines {
      fill: var(--colour-logo-speedlines);
    }
  }
}
