.table {
  text-align: left;
  border-collapse: collapse;
  overflow-x: auto;
  min-width: 700px;

  th, td {
    padding: $space-sm $space-lg $space-sm 0px;
    border: none;
    @include divider-line;
  }

  th:last-child,
  td:last-child {
    padding-right: 0px;
  }

  thead {
    th {
      font-weight: 500;
      font-size: $font-size-xs;
      text-transform: uppercase;
    }
  }

  &.has-tiny-text {
    font-size: $font-size-xs;
  }
}

.table-overflow-handler {
  overflow-x: auto;
}
