.stack {
  display: flex;
  flex-direction: column;
  gap: $space-sm;

  @include hidden-when-empty;

  &.has-gap-xs {
    gap: $space-xs;
  }

  &.has-gap-md {
    gap: $space-md;
  }

  &.has-gap-lg {
    gap: $space-lg;
  }

  &.has-gap-xl {
    gap: $space-xl;
  }
}
