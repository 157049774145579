$skeleton-fill: rgba(147,147,147,0.3);

@keyframes pulse-fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.36;
  }
}

.skeleton {
  animation: pulse-fade 0.6s ease-in-out infinite alternate;
}

.skeleton.is-heading {
  $line-ratio: aspect(4,5);

  max-width: 65ch;
  height: $font-size-xxl;
  margin-bottom: $space-md;

  &::after {
    content: '';
    width: calc(#{$line-ratio} * 100%);
    height: 100%;
    background: $skeleton-fill;
    display: block;
  }

  &.is-small {
    height: $font-size-xl;
  }

  &.is-large {
    height: $font-size-xxxl;
  }
}

.skeleton.is-chip {
  width: px-to-rem(180px);
  height: px-to-rem(36px);
  background: $skeleton-fill;

  @include round-radius;
}

.skeleton.is-paragraph {
  --lines: 2;

  $line-size: $font-size-sm;
  $line-height: $line-size * 2;
  $line-size-svg: strip-unit($line-size * 16);
  $line-height-svg: strip-unit($line-height * 16);
  $last-line-ratio: aspect(2,5);

  width: 100%;
  max-width: 65ch;
  height: calc((#{$line-height} * var(--lines)) - #{$line-height});
  margin-bottom: $line-height + $line-size;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$skeleton-fill}' %3E%3Cpattern id='lines' x='0' y='0' width='100%25' height='#{$line-height-svg}' patternUnits='userSpaceOnUse' %3E%3Crect class='checker' x='0' width='100%25' height='#{$line-size-svg}' y='0'/%3E%3C/pattern%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='url(%23lines)' /%3E%3C/svg%3E");
  position: relative;

  &::after {
    content: '';
    width: calc(#{$last-line-ratio} * 100%);
    height: $line-size;
    position: absolute;
    top: 100%;
    background: $skeleton-fill;
  }
}
