.callout {
  display: flex;
  padding: $space-md;
  font-size: $font-size-xs;
  background-color: var(--colour-bg-high-contrast);
  gap: $space-sm;

  &:not(.has-border) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .icon {
    font-size: $font-size-sm;
    margin-top: px-to-rem(2px);
    flex-shrink: 0;
  }

  .title {
    font-size: $font-size-sm;
    font-weight: 500;

    & + p {
      margin-top: $space-xs;
    }
  }

  p {
    margin-bottom: 0px;
  }

  @include is-tablet {
    font-size: $font-size-sm;
    padding: $space-lg;
    margin-bottom: $space-sm;

    .icon {
      font-size: $font-size-md;
    }

    .title {
      font-size: $font-size-md;

      & + p {
      margin-top: $space-sm;
      }
    }
  }

  .container > &:not(.has-border) {
    padding: $space-md 0;

    @include is-tablet {
      padding: $space-lg;
    }
  }

  &.has-coloured-icon .icon {
    color: var(--colour-callout-icon);
  }

  &.has-border {
    display: inline-flex;
    border: 1px solid var(--colour-callout-border);
    border-radius: 5px;

    &.has-error {
      border-color: var(--colour-callout-border-error);
    }
  }
}

.callout.is-call-to-action {
  $callout-padding: $space-md;

  border-radius: 16px;
  color: var(--colour-text-invert);
  font-size: $font-size-sm;
  padding: $callout-padding;
  gap: $callout-padding;
  align-items: center;

  @include background-gradient-primary;

  img.is-aligned-bottom {
    margin-bottom: -$callout-padding;
  }

  .title {
    font-size: $font-size-xl;
    font-weight: normal;
  }

  @include is-tablet {
    font-size: $font-size-lg;

    .title {
      font-size: $font-size-xxl;
    }
  }
}

// Interactive callout button
:where(button, a).callout {
  width: 100%;

  @include has-mouse-pointer {
    &:hover {
      background-color: var(--colour-action-hover);
    }
  }

  @include remove-hover-underline;
}
