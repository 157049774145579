details.collapse {
  $summary-gap: $space-md;
  $summary-line-height: 1.6em;

  p {
    margin-bottom: $summary-gap;
    font-size: $font-size-xs;
    padding: 0 px-to-rem(6px);

    @include is-tablet {
      font-size: $font-size-md;
    }
  }

  & + & {
    border-top: 1px solid transparent;

    &:not([open]) {
      border-color: var(--colour-collapse-divider);
    }
  }

  > summary {
    padding: $summary-gap*0.5 0 $summary-gap*0.5 px-to-rem(6px);
    font-size: $font-size-xs;
    cursor: pointer;
    list-style: none;
    display: flex;
    line-height: $summary-line-height;
    align-items: center;
    border-radius: 4px;

    > span {
      flex-grow: 1;
    }

    &::-webkit-details-marker {
      display: none;
    }

    &::after {
      content: '';
      width: $font-size-xs;
      height: $font-size-xs;
      margin: px-to-rem(10px);
      display: block;
      transform: rotate(-180deg) scale(-1);
      mask: url("data:image/svg+xml,%3Csvg id='Bold' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-down-1%3C/title%3E%3Cpath d='M12,19.5a2.3,2.3,0,0,1-1.729-.78L.46,7.568A1.847,1.847,0,0,1,3.233,5.129l8.579,9.752a.25.25,0,0,0,.376,0l8.579-9.752A1.847,1.847,0,1,1,23.54,7.568L13.732,18.716A2.31,2.31,0,0,1,12,19.5Z'/%3E%3C/svg%3E");
      flex-shrink: 0;
      transition: transform 0.25s ease-in-out;
      background-color: var(--colour-text);
    }

    @include is-tablet {
      font-size: $font-size-lg;

      &::after {
        width: $font-size-sm;
        height: $font-size-sm;
      }
    }
  }

  &[open] {
    > summary {
      background-color: var(--colour-collapse-open);
      box-shadow: 0 0 0 4px var(--colour-collapse-open);
      margin-bottom: $summary-gap;
    }

    & > summary:after {
      transform: rotate(0deg) scale(-1);
    }
  }
}
