.order-status {
  > div {
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: $space-xs;
    }
  }

  &.is-header > div {
    @include is-desktop {
      justify-content: center;
    }
  }

  .headline {
    font-size: $font-size-xl;

    @include is-tablet {
      font-size: $font-size-xxl;
    }
  }

  .step-description {
    font-size: $font-size-sm;
    align-items: center;

    > .icon {
      margin-right: $space-xs;
    }

    @include is-tablet {
      font-size: $font-size-lg;
    }
  }
}
