.google-map {
	$gm-control-size: 40px;
	$gm-control-spacing: 10px;

	width: 100%;

	.custom-map-ui.is-ui-control,
	.gm-fullscreen-control,
	.gm-bundled-control-on-bottom [style~="border-radius:"] {
		border-radius: 6px !important;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12) !important;
		z-index: 1 !important;
	}

	div[role=dialog] {
		padding: 12px !important;

		div {
			overflow: hidden !important;
		}
	}

	// Set popover UI to use Geograph
	.custom-map-popover {
		font-family: "Founders Grotesk", sans-serif;
		font-size: $font-size-xs;
		line-height: 1.25;
		text-align: center;
		margin: 0 10px;
		overflow: hidden;
		color: var(--colour-black);

		strong {
			font-weight: 500;
		}
	}

	.custom-map-ui {
		&.is-ui-control {
			$line-height: 1.25;

			display: flex;
			gap: $space-xs;
			margin: px-to-rem(10px) !important;
			padding: px-to-rem(10px);
			background-color: var(--colour-bg-high-contrast);
			color: var(--colour-text);
			font-family: "Founders Grotesk", sans-serif;
			font-size: $font-size-xs;
			line-height: $line-height;

			& > .icon {
				margin: math.div(($line-height - 1), 2) * 1em 0;
			}

			&.is-top-left {
				width: calc(100% - #{$gm-control-spacing}*4 - #{$gm-control-size});
				max-width: 27ch;

				@include is-tablet {
					width: auto;
					max-width: 65ch;
				}
			}
		}

		&.is-map-overlay {
			position: absolute;
			top: 0 !important;
			left: 0 !important;
			width: 100% !important;
			height: 100% !important;

			&.is-gradient-top {
				background: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
				pointer-events: none;
			}
		}
	}
}

.is-order-map {
	transition: filter cubic-bezier(.45,.05,.55,.95) 1s;

	@include aspect-ratio(7,5);

	@include is-desktop {
		@include aspect-ratio(3,1);
	}

	&.has-stale-location {
		filter: grayscale(1);
	}
}

.is-checkout-map {
	border-radius: 10px;
	margin-bottom: $space-sm;

	@include aspect-ratio(16,9);

	@include is-tablet {
		float: right;
		max-width: px-to-rem(320px);

		@include aspect-ratio(3,2);
	}

	@include is-desktop-wide {
		max-width: px-to-rem(380px);

		@include aspect-ratio(16,9);
	}
}
