%product-listing {
  display: flex;
  padding: $space-md 0px;
  align-items: flex-start;
  background-color: var(--colour-bg-high-contrast);
  text-decoration: none;

  @include remove-hover-underline;
  @include remove-default-tap-highlights;

  @include is-not-tablet {
    &:not(:last-of-type) {
      @include divider-line;
    }
  }

  &.is-unavailable {
    .title,
    .description,
    .price {
      @include muted-text;
    }

    @include is-tablet {
      background-color: unset;
      border: 1px solid var(--colour-action-border);
    }
  }

  .title {
    order: 1;
    font-size: $font-size-md;
    font-weight: 500;
    margin-right: auto;
  }

  .badge.is-quantity {
    order: 2;
  }

  .description {
    order: 3;
    width: 100%;
    @include tiny-muted-text;

    @include hidden-when-empty;

    > p {
      margin: 0px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;

      @include hidden-when-empty;

      &:not(:empty) {
        margin-top: $space-sm;
      }
    }
  }

  .price {
    order: 4;
    align-self: center;
    font-size: $font-size-sm;
    font-weight: 500;
    margin-right: $space-sm;
    margin-top: $space-sm;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: $space-sm;

    @include hidden-when-empty;

    @include is-desktop {
      flex-direction: row;
    }
  }

  .attributes {
    order: 5;
    align-self: center;
    margin-top: $space-sm;

    > * {
      margin-top: px-to-rem(4px);
      margin-bottom: px-to-rem(4px);
      margin-right: $space-xs;
    }

    @include hidden-when-empty;
  }

  .deals {
    order: 6;
    margin-top: $space-sm;
    width: 100%;
  }

  @include is-tablet {
    align-items: center;
    padding: $space-xl;

    &:not(:last-of-type) {
      margin-bottom: $space-sm;
    }

    .title {
      order: 1;
      margin-right: inherit;
      margin-bottom: 0px;
      font-size: $font-size-xl;
      margin-right: $space-sm;
    }

    .badge.is-quantity {
      order: 4;
      margin-left: $space-sm;
      align-self: center;
    }

    .description {
      order: 5;
      font-size: $font-size-sm;
    }

    .price {
      order: 3;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-right: 0px;
      margin-left: auto;
      font-size: $font-size-lg;
    }

    .attributes {
      order: 2;
      margin-top: 0px;
    }
  }
}

.product-listing {
  @extend %product-listing;

  flex-wrap: wrap;
}

.product-listing-with-image {
  $image-ratio: aspect(1,1);
  $image-ratio-over-desktop-wide: aspect(3,4);

  @extend %product-listing;

  .description > p {
    -webkit-line-clamp: 6;
  }

  .product-details {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  .product-image {
    background-position: center;
    background-size: cover;
    width: 100%;
    max-width: px-to-rem(110px);
    margin-left: $space-sm;
    position: relative;
    flex-shrink: 0;
    align-self: flex-start;
    background-image: var(--colour-image-product-placeholder);

    &::after {
      content: "";
      height: 0;
      width: 0;
      display: block;
      padding-bottom: calc(#{$image-ratio} * 100%);
    }

    .badge.is-quantity {
      position: absolute;
      bottom: 100%;
      left: 100%;
      transform: translate(-50%, 50%);
      margin: 0px;
    }
  }

  &.is-unavailable .product-image {
    mix-blend-mode: luminosity;
    opacity: 0.3;
  }

  @include is-tablet {
    .product-image {
      max-width: px-to-rem(120px);
      margin-left: $space-md;
    }
  }

  @include is-desktop {
    .product-image {
      max-width: px-to-rem(160px);
      margin-left: $space-xl;

      &::after {
        padding-bottom: calc(#{$image-ratio-over-desktop-wide} * 100%);
      }
    }
  }

  @include is-desktop-wide {
    .product-image {
      max-width: px-to-rem(200px);
    }
  }
}

.product-listing-is-grid {
  --max-lines: 3;
  --last-lines: 1;
  --title-line-height: 1.4;
  --space-character-width: 0.306ch;

  background-color: var(--colour-bg-high-contrast);
  padding: px-to-rem(12px);
  display: flex;
  flex-direction: column;
  gap: $space-xs;
  font-size: $font-size-xs;
  text-decoration: none;
  align-items: flex-start;
  position: relative;

  @include remove-hover-underline;
  @include remove-default-tap-highlights;

  @include is-tablet {
    padding: $space-md;
    gap: $space-sm;
  }

  > .product-image {
    width: 100%;
    aspect-ratio: 1/1;
    background-size: cover;
    background-position: center;
    background-image: var(--colour-image-product-placeholder);
    position: relative;

    .badge.is-quantity {
      position: absolute;
      inset-block-start: -$space-xs;
      inset-inline-end: -$space-xs;
    }
  }

  > .product-details {
    height: calc(1em * (var(--max-lines)) * var(--title-line-height));

    p {
      display: flex;
      flex-wrap: wrap;
      column-gap: var(--space-character-width);
      line-height: var(--title-line-height);
      margin: 0px;
      overflow-wrap: anywhere;

      & > span:first-of-type {
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: calc(var(--max-lines) - var(--last-lines));
        overflow: hidden;
        overflow: clip;
      }

      &:not(:has(span:not(:first-of-type))) > span {
        -webkit-line-clamp: var(--max-lines);
      }
    }
  }

  > .product-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: px-to-rem(20px);

    > .button {
      position: absolute;
      inset-inline-end: px-to-rem(-8px);
    }

    > .price {
      @include bold-text;
    }
  }

  > .quick-quantity-controls {
    width: 100%;
    position: absolute;
    inset-inline: 0;
    inset-block-end: 0;
    padding: px-to-rem(4px);

    @include is-tablet {
      padding: $space-sm;
    }
  }

  &.is-unavailable {
    background-color: unset;

    .product-details,
    .price {
      @include muted-text;
    }

    .product-image {
      mix-blend-mode: luminosity;
      opacity: 0.3;
    }
  }
}

.modal .product-listing {
  padding: 0px;

  .description > p {
    -webkit-line-clamp: 8;
    white-space: pre-line;
  }

  .title {
    font-size: $font-size-xxl;
    font-weight: normal;
  }

  @include is-tablet {
    .title {
      font-size: $font-size-xxl;
    }

    .attributes {
      order: 5;
      margin-top: $space-sm;
    }
  }
}

// Fixes a specific bug on iOS 16.2 and older which prevents long descriptions from being collapsed
// with a 'Full description' button on load due to some weird edge case with the visibility property
// inheritance. Setting the visibility property alone is dangerous (it could secretly block clicks
// to UI underneath when the parent, the modal in this case is set to hidden). So this also sets the
// pointer events to none so that it can't block clicks to the UI underneath the modal. We can get rid
// of this nonsense when iOS 16.2 is no longer in active use.
.modal:not(.is-active) .product-listing .description > p {
  visibility: visible;
  pointer-events: none;
}
