.restaurant-header {
  display: flex;
  flex-direction: column;

  p {
    font-size: $font-size-sm;
  }

  .details {
    > :first-child {
      margin-top: 0px;
    }

    > :last-child {
      margin-bottom: 0px;
    }
  }

  .attributes {
    display: flex;
    flex-wrap: wrap;
    gap: $space-sm $space-md;
  }

  .header-image {
    position: relative;
    order: 1;
    width: 100%;
    background-position: center;
    background-size: cover;
    min-height: $floating-back-button-offset;
    flex-shrink: 0.25;
    background-color: var(--colour-image-placeholder);
    aspect-ratio: 8 / 5;

    @include floating-back-button-offset;

    &.is-closed::after {
      height: 100%;
      width: 100%;
      background-color: var(--colour-image-background-fade);
    }
  }

  .header-controls {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: $space-sm;

    @include vertical-container-spacing;
    @include horizontal-container-spacing;
    padding-top: $floating-back-button-spacing-y;
  }

  > section {
    order: 2;
    flex-grow: 1;
  }

  @include is-tablet {
    flex-direction: row;
    justify-content: space-between;

    p {
      font-size: $font-size-md;
    }

    .header-image,
    > section {
      order: revert;
    }

    .header-image {
      margin-top: 0px;
      width: 50%;
      max-height: 600px;
      max-width: 870px;
    }
  }

  @include is-desktop {

    .details {
      > *:not(:first-child):not(:last-child):not(.badge) {
        margin: $space-lg 0px;
      }
    }

    h2 {
      font-size: px-to-rem(56px);
    }
  }
}
