html {
  box-sizing: border-box;
}

body {
  background-color: var(--colour-bg-high-contrast);
  overflow-x: hidden;
}

body,
fieldset,
textarea {
  margin: 0px;
}

fieldset {
  padding: 0px;
}

hr,
fieldset {
  border: 0px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Disables text selection for all touch based devices
// disables the annoying 'tap to search' android feature
@include is-touch-device {
  *:not(input):not(textarea):not([contenteditable]) {
    user-select: none;
  }
}

::selection {
  background-color: var(--colour-user-text-selection);
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  font-feature-settings: inherit;
  color: inherit;
}

// Removes the default 2px input margin in webkit based browsers
input {
  margin: 0;
}

button,
[type="submit"] {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

// Ignore a <form> element to stop it breaking the layout in cases where a form is used to
// house hidden input fields. Also ignores the <form> element generated automatically by
// the button_to rails helper method so that they sit inline with other button elements.
form.is-hidden,
form.button_to {
  display: contents;
}

// Hide annoying fixed position reCAPTCHA badge based on Google's FAQ / guidelines
// https://developers.google.com/recaptcha/docs/faq
.grecaptcha-badge {
  visibility: hidden;
}
