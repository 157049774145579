%badge-large {
  font-size: $font-size-sm;
}

%badge-bubble {
  padding: px-to-rem(6px) px-to-rem(10px);

  @include round-radius;
  @include hidden-when-empty;
}

.badge {
  font-size: $font-size-xs;
  display: inline-flex;
  align-items: center;
  gap: px-to-rem(8px);
  color: var(--colour-badge-text);
  font-weight: normal;

  > .icon {
    color: var(--colour-badge-icon);
  }

  &.is-large {
    @extend %badge-large;
  }
}

.badge.is-flat {
  @extend %badge-bubble;

  background-color: var(--colour-badge-flat-fill);

  > .icon {
    color: inherit;
  }
}

.badge.is-subtle {
  @extend %badge-bubble;

  background-color: var(--colour-badge-subtle-fill);

  > .icon {
    color: inherit;
  }
}

.badge.is-strong {
  @extend %badge-bubble;

  background-color: var(--colour-badge-strong-fill);
  color: var(--colour-badge-strong-text);

  > .icon {
    color: inherit;
  }
}

.badge.is-important {
  @extend %badge-bubble;

  background-color: var(--colour-badge-important-fill);
  color: var(--colour-badge-important-text);

  > .icon {
    color: inherit;
  }
}

.badge.is-success {
  @extend %badge-bubble;

  background-color: var(--colour-badge-success-fill);
  color: var(--colour-badge-success-text);

  > .icon {
    color: inherit;
  }
}

.badge.is-exclusive-partner {
  @extend %badge-bubble;

  background: var(--gradient-exclusive-partner);
  color: var(--colour-badge-exclusive-text);

  &:not(.is-large) > svg {
    width: px-to-rem(16px);
    height: px-to-rem(16px);
  }

  @include bold-text;

  &.is-large {
    padding: px-to-rem(8px) px-to-rem(14px);
  }
}

.badge.is-dietary-choice-vegetarian {
  @extend %badge-bubble;

  background-color: var(--colour-badge-vegetarian-fill);

  > .icon {
    color: var(--colour-badge-vegetarian-icon);
  }
}

.badge.is-dietary-choice-vegan {
  @extend %badge-bubble;

  background-color: var(--colour-badge-vegan-fill);

  > .icon {
    color: var(--colour-badge-vegan-icon);
  }
}

// Hide the vegetarian badge if vegan one is also present on the menu item
.badge.is-dietary-choice-vegan ~ .badge.is-dietary-choice-vegetarian {
  @include completely-hidden-element;
}

.badge.is-dietary-choice-gluten-free {
  @extend %badge-bubble;

  background-color: var(--colour-badge-gf-fill);

  > .icon {
    color: var(--colour-badge-gf-icon);
  }
}

.badge.is-dietary-choice-contains-nuts {
  @extend %badge-bubble;

  background-color: var(--colour-badge-cn-fill);

  > .icon {
    color: var(--colour-badge-cn-icon);
  }
}

.badge.is-dietary-choice-dairy-free {
  @extend %badge-bubble;

  background-color: var(--colour-badge-df-fill);

  > .icon {
    color: var(--colour-badge-df-icon);
  }
}

.badge.is-quantity {
  @extend %badge-large;
  @extend %badge-bubble;

  background-color: var(--colour-badge-quantity-fill);
  color: var(--colour-badge-quantity-text);
  padding: px-to-rem(4px) px-to-rem(10px);
  gap: px-to-rem(4px);

  &:not(:empty):not(.is-round)::before {
    content: '\00d7';
  }

  @include hidden-when-empty;

  > .icon {
    color: var(--colour-badge-icon);
  }
}

.badge.is-round {
  font-size: $font-size-xs;
  min-width: px-to-rem(30px);
  height: px-to-rem(30px);
  border-radius: 50%;
  justify-content: center;
  padding: 0;

  &.is-tiny {
    min-width: px-to-rem(24px);
    height: px-to-rem(24px);
  }
}

a.badge {
  &:where(:focus, :hover) {
    background-color: var(--link-selector-hover);
    box-shadow: 0px 0px 0px 8px var(--link-selector-hover);
    border-radius: 3px;
  }

  @include remove-default-tap-highlights;
  @include remove-hover-underline;
}