[data-tooltip] {
  position: relative;

  &::after {
    content: attr(data-tooltip);
    display: flex;
    position: absolute;
    top: calc(100% + px-to-rem(8px));
    left: 50%;
    transform: translateX(-50%);
    padding: $space-xs px-to-rem(12px);
    font-size: $font-size-xs;
    line-height: 1;
    color: var(--colour-text-invert);
    background-color: var(--colour-tooltip-fill);
    border-radius: 6px;
    z-index: $layer-high;
    white-space: nowrap;
    transition: all 0.125s 0.5s ease-in;

    @include hidden-element-can-animate;
    @include elevation-low;
  }

  @include has-mouse-pointer {
    &:hover::after {
      opacity: 1;
      visibility: visible;
    }
  }
}
