.pac {
  $pac-icon-size: 18px;
  $pac-item-padding: px-to-rem(12px);

  &-container {
    border: none;
    border-radius: 5px;
    margin-top: px-to-rem(4px);
    font-family: inherit;
    font-feature-settings: inherit;
    font-size: $font-size-sm;
    padding: $space-xs 0px;
    box-sizing: content-box;
    background-color: var(--colour-bg-high-contrast);

    @include elevation-middle;
  }

  &-icon,
  .hdpi &-icon {
    grid-area: icon;
    margin: px-to-rem(2px) 0px 0px 0px;
    width: px-to-rem($pac-icon-size);
    height: px-to-rem($pac-icon-size);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FF3312'%3E%3Ctitle%3Epin%3C/title%3E%3Cpath d='M12,0A8.009,8.009,0,0,0,4,8c0,3.51,5,12.025,7.148,15.524A1,1,0,0,0,12,24a.991.991,0,0,0,.852-.477C15,20.026,20,11.514,20,8A8.009,8.009,0,0,0,12,0Zm0,11.5A3.5,3.5,0,1,1,15.5,8,3.5,3.5,0,0,1,12,11.5Z'/%3E%3C/svg%3E");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &-item {
    border: none;
    padding: $pac-item-padding;
    font-size: $font-size-xs;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: px-to-rem($pac-icon-size) minmax(0, 1fr);
    gap: px-to-rem(4px) $pac-item-padding;
    color: var(--colour-text-muted);
    grid-template-areas:
      "icon address"
      "icon location"
    ;

    @include has-mouse-pointer {
      &:hover,
      &-selected {
        background-color: var(--colour-places-autocomplete-option-hover);
      }
    }

    > span {
      line-height: 1.3;
    }

    &-query {
      grid-area: address;
      font-size: $font-size-sm;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--colour-text);
    }

    :last-child {
      grid-area: location;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-matched {
    font-weight: normal;
  }

  &-logo:after {
    $google-logo-height: 18px;

    height: 0px;
    padding: calc(#{$pac-item-padding} + #{$google-logo-height}) 0px 0px 0px;
    background-position: $space-sm $pac-item-padding;

    :root body[data-theme="dark"] & {
      filter: invert(1) saturate(0) brightness(100);
    }
  }
}

.text-input.has-places-autocomplete {
  z-index: $layer-low;

  input {
    @include animate-subtle;
  }

  &:focus-within {
    z-index: $layer-highest;

    input:focus {
      box-shadow: 0px 0px 0px 200vh var(--colour-places-autocomplete-dimming);
    }
  }
}
