section.bold-section {
  background-color: var(--colour-bg-bold);
  color: var(--colour-text-invert);
}

section.muted-section {
  background-color: var(--colour-bg-muted);
  color: var(--colour-text);
}

section.video-section {
  $image-ratio: aspect(1,1);
  $image-ratio-desktop: aspect(9,21);

  position: relative;
  height: 0px;
  width: 100%;
  padding-bottom: calc(#{$image-ratio} * 100%);
  background-color: var(--colour-image-placeholder);

  @include is-desktop {
    padding-bottom: calc(#{$image-ratio-desktop} * 100%);
  }

  video {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: $layer-lowest;
  }

  > .container {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: $layer-low;

    @include is-desktop {
      text-align: center;
    }

    h2 {
      color: var(--colour-white);
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #1E1E1E 6%, rgba(30, 30, 30, 0) 75%), linear-gradient(0deg, rgba(30, 30, 30, 0.08), rgba(30, 30, 30, 0.08));
    background-position: center;
    background-size: cover;
    z-index: $layer-lowest;

    @include is-desktop {
      background: linear-gradient(0deg, #1E1E1E 6%, rgba(30, 30, 30, 0) 50%), linear-gradient(0deg, rgba(30, 30, 30, 0.08), rgba(30, 30, 30, 0.08));
    }
  }
}
