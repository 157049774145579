.scroller {
  position: relative;

  .scroller-content {
    display: flex;
    overflow: auto;
    scroll-snap-type: x proximity;
    scroll-behavior: smooth;

    gap: $mobile-container-spacing;

    @include is-tablet {
      gap: $tablet-container-spacing;
    }

    @include is-desktop {
      gap: $desktop-container-spacing;
    }

    @include is-desktop-wide {
      gap: $desktop-wide-container-spacing;
    }

    @include vertical-container-spacing;
    @include horizontal-container-spacing;

    @include is-touch-device {
      @include hide-scrollbars;
    }

    @include subtle-scrollbars;

    > .item {
      width: 100%;
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      scroll-snap-align: center;
    }
  }

  .scroller-controls {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    @include horizontal-container-spacing;

    .scroller-next,
    .scroller-previous {
      pointer-events: initial;
    }

    .scroller-next {
      left: 0px;
    }

    .scroller-previous {
      right: 0px;
    }
  }
}

.scroller.has-control-spacing {
  .scroller-content > .item {
    @include horizontal-container-spacing;
  }
}

.scroller.is-expanded-desktop {
  @include is-desktop {
    .scroller-content {
      @include three-column-grid;
    }

    .scroller-controls {
      @include completely-hidden-element;
    }
  }

  &.has-control-spacing .scroller-content > .item {
    @include is-desktop {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
