.product-image-large {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  min-height: $floating-back-button-offset;
  background-color: var(--colour-image-placeholder);
  background-size: cover;
  background-position: center;
  position: relative;

  @include floating-back-button-offset;

  > .images-container {
    --product-image-large-aspect: 4/3;

    width: 100%;
    display: flex;
    overflow-x: auto;
    aspect-ratio: var(--product-image-large-aspect);
    scroll-snap-type: inline mandatory;

    @include hide-scrollbars;
  }

  @include is-tablet {
    padding: $space-md 0px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--colour-image-background-fade);
      backdrop-filter: blur(10px) saturate(1.2);
    }
  }

  &.has-background-image {
    :where(&, & > .images-container > .image-container) {
      background-image: var(--jpeg-2x-path);
      background-image: image-set(
        var(--webp-2x-path) 2x,
        var(--webp-1x-path) 1x
      );
    }

    > .images-container > .image-container {
      width: 100%;
      background-color: var(--colour-image-placeholder);
      background-size: cover;
      background-position: center;
      aspect-ratio: var(--product-image-large-aspect);
      scroll-snap-align: center;
      scroll-snap-stop: always;
    }

    @include is-tablet {
      > .images-container {
        max-width: 470px;
        border-radius: 16px;
        box-shadow: 2px 0px 24px 0px rgba(0, 0, 0, 0.04), -2px 0px 24px 0px rgba(0, 0, 0, 0.04);
        z-index: $layer-lowest;
      }
    }
  }
}

.product-image-large.is-square {
  background-size: 225%;

  @include is-tablet {
    &::after {
      backdrop-filter: blur(26px) saturate(1.2);
    }

    > .images-container {
      --product-image-large-aspect: 1/1;

      max-width: 360px;
    }
  }

  @include is-not-tablet {
    aspect-ratio: var(--product-image-large-aspect);
    background: var(--colour-image-background-fill);
  }

  > .images-container > .image-container {
    background-size: contain;
    background-repeat: no-repeat;
    border: $space-lg solid var(--colour-image-background-fill);
    background-color: var(--colour-image-background-fill);
  }
}

.images-container {
  > .navigation-controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: inherit;
    pointer-events: none;

    @include is-tablet {
      padding: $space-md 0px;
    }

    > button {
      pointer-events: initial;
    }
  }

  > .pagination-indicator {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: inherit;

    @include is-tablet {
      padding: $space-md 0px;
    }

    @include remove-default-tap-highlights;

    > .indicator-dot {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      cursor: pointer;

      > div {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--colour-action-disabled);

        @include animate-subtle;
      }

      &.is-active {
        div {
          background: var(--colour-action-active);
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
