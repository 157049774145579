.text-overflow-toggle {
  label {
    display: inline-flex;
    align-items: center;
    gap: $space-xs;
    color: var(--colour-text);
    padding: px-to-rem(16px) 0px;
    border-radius: 6px;
    cursor: pointer;

    .icon {
      font-size: 0.8em;
      color: var(--colour-overflow-toggle-icon);
    }
  }

  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--line-clamp, none);
    overflow: hidden;
  }

  input {
    @include visually-hidden-element;

    &:checked ~ p {
      -webkit-line-clamp: unset !important;
    }

    &:checked ~ label {
      > span {
        @include completely-hidden-element;
      }

      &::after {
        content: var(--collapse-label, 'Collapse');
      }
    }

    @include has-mouse-pointer {
      &:focus-visible ~ label {
        border-color: transparent;
        outline: 4px solid var(--colour-focus-outline);
        outline-offset: 3px;
      }

      @supports not selector(:focus-visible) {
        &:focus ~ label {
          outline: 4px solid var(--colour-focus-outline);
          outline-offset: 3px;
        }
      }
    }
  }
}
