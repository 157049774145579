// Adds flexbox to element
.is-flexbox {
  display: flex !important;
}

.is-inline-flexbox {
  display: inline-flex !important;
}

// Flex direction
.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

// Flex wrap
.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

// Justify content
.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

// Align content
.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

// Align items
.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

// Align self (for flex child)
.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

// Child flex sizes
.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.min-width-0 {
  min-width: 0px;
}

// Trick for use in complex responsive wrapped flex layouts (removes need for a media query)
.flex-grow-9999 {
  flex-grow: 9999 !important;
}

.is-flex-gap-xs {
  gap: $space-xs;
}

.is-flex-gap-sm {
  gap: $space-sm;
}

.is-flex-gap-md {
  gap: $space-md;
}

.is-flex-gap-lg {
  gap: $space-lg;
}

.flex-1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}
