.notice {
  display: flex;
  align-items: center;
  padding: $space-sm 0px;
  background-color: var(--colour-notice-fill);
  font-size: $font-size-xs;
  line-height: 1.5;

  @include horizontal-container-spacing;
  @include extra-desktop-space-horizontal;
  @include choice-strip-end-space;

  > * {
    margin: 0px;
  }

  > .icon {
    font-size: inherit;
    margin-right: $space-sm;
    flex-shrink: 0;
  }

  .notice-controls {
    margin-left: auto;
    padding-left: $space-sm;

    :not(:last-child) {
      margin-right: $space-xs;
    }
  }

  @include is-tablet {
    font-size: $font-size-sm;
  }

  &.is-deal {
    color: var(--colour-notice-deal-text);
    background-color: var(--colour-notice-deal-fill);

    a {
      border-color: var(--colour-notice-deal-text);
    }
  }

  &.is-strong {
    color: var(--colour-notice-strong-text);
    background-color: var(--colour-notice-strong-fill);
  }

  &.is-important {
    background-color: var(--colour-notice-important-fill);
  }
}

.notice.is-fixed-bottom {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 6px;
  z-index: $layer-high;
  margin: 0px $mobile-container-spacing;

  :not(.fixed-bottom-element) > & {
    margin: $mobile-container-spacing $mobile-container-spacing;
  }

  @include elevation-low;

  @include is-tablet {
    left: initial;
    margin: $tablet-container-spacing $tablet-container-spacing;
    padding: $space-sm $space-md;
  }

  @include is-desktop {
    margin: $desktop-container-spacing $desktop-container-spacing;
  }

  @include is-desktop-wide {
    margin: $desktop-container-spacing $extra-desktop-container-spacing;
  }

  .fixed-bottom-element > & {
    position: absolute;
    bottom: 100%;
  }
}

.notice.is-raised {
  border-radius: 5px;
  border: 1px solid var(--colour-notice-raised-border);

  @include elevation-low;
}
