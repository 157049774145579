.catalog-section-heading {
  padding: $space-lg 0px;

  :where(h1, h2, h3, h4, h5) {
    margin: 0;
  }

  > p {
    margin-top: $space-sm;

    @include hidden-when-empty;
  }

  > .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-block-size: $button-icon-only-size;
  }

  @include is-tablet {
    @include vertical-container-spacing;
    @include extra-desktop-space-vertical;
  }
}
