.order-image {
  $image-ratio: aspect(5,8);
  $image-ratio-tablet: aspect(1,3);

  width: 100%;
  height: 0px;
  position: relative;
  padding-bottom: calc(#{$image-ratio} * 100%);
  background-color: var(--colour-image-placeholder);
  background-position: center;
  background-size: cover;

  @include is-tablet {
    padding-bottom: calc(#{$image-ratio-tablet} * 100%);
  }
}
