@mixin two-column-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $space-md;
}

@mixin three-column-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $space-md;
}

@mixin four-column-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: $space-md;
}

@mixin five-column-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: $space-lg;
}

.grid {
  display: grid;
  padding: 0px;

  .column {
    > section:first-of-type {
      padding-top: 0px;
    }

    &.has-section-dividers {
      > section:not(:last-of-type) {
        @include divider-line;
      }

      &-below-mobile {
        > section:not(:last-of-type) {
          @include divider-line;

          @include is-tablet {
            border-bottom: revert;
          }
        }
      }
    }

    &.has-container-spacing-top {
      @include vertical-container-spacing;
      @include extra-desktop-space-vertical;

      &-above-tablet {
        padding-top: 0px;

        @include is-tablet {
          @include vertical-container-spacing;
          @include extra-desktop-space-vertical;
        }

        @include is-desktop-wide {
          padding-top: $extra-desktop-container-spacing;
        }
      }

      &-above-desktop {
        padding-top: 0px;

        @include is-desktop {
          @include vertical-container-spacing;
          @include extra-desktop-space-vertical;
        }

        @include is-desktop-wide {
          padding-top: $extra-desktop-container-spacing;
        }
      }
    }
  }
}

.grid.has-2-breaking-columns {
  grid-template-columns: 1fr;
  gap: $space-md;

  &.has-reversed-order-above-tablet > :last-child {
    @include is-tablet {
      order: -1;
    }
  }

  @include is-tablet {
    @include two-column-grid;
  }
}

.grid.has-3-breaking-columns {
  grid-template-columns: 1fr;
  gap: $space-md;

  @include is-tablet {
    @include two-column-grid;
  }

  @include is-desktop {
    @include three-column-grid;
  }
}

.grid.has-4-breaking-columns {
  grid-template-columns: 1fr;
  gap: $space-md;

  @include is-tablet {
    @include two-column-grid;
  }

  @include is-desktop {
    @include three-column-grid;
  }

  @include is-desktop-wide {
    @include four-column-grid;
  }
}

.grid.has-5-breaking-columns {
  grid-template-columns: 1fr;
  gap: $space-md;

  @include is-tablet {
    @include two-column-grid;
  }

  @include is-desktop {
    @include three-column-grid;
  }

  @include is-desktop-wide {
    @include four-column-grid;
  }

  @include is-desktop-super-wide {
    @include five-column-grid;
  }
}

.grid.has-wide-gutters {
  @include is-tablet {
    column-gap: $space-lg;
  }

  @include is-desktop {
    column-gap: $extra-desktop-container-spacing;
  }
}

.grid.has-aside {
  grid-template-columns: 1fr;

  @include is-desktop {
    grid-template-columns: 2fr minmax(380px, 1fr);

    &.has-container-spacing {
      @include horizontal-container-spacing;
      @include extra-desktop-space-horizontal;
    }
  }

  &.has-aside--left {
    @include is-desktop {
      grid-template-columns: minmax(380px, 1fr) 2fr;
    }
  }

  // Sticky grid columns must be set on a child of the column
  .column > .is-sticky-column {
    @include is-desktop {
      position: sticky;
      top: 0;

      @include vertical-container-spacing('padding-top');
    }

    @include is-desktop-wide {
      padding-block-start: $extra-desktop-container-spacing;
    }
  }

  &.has-grid-gutters {
    @include is-tablet {
      gap: $space-lg;
    }

    @include is-desktop {
      gap: $space-lg;
    }

    @include is-desktop-wide {
      gap: $extra-desktop-container-spacing;
    }
  }
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: px-to-rem(4px);

  @include is-tablet {
    grid-template-columns: repeat(4, 1fr);
    gap: $space-xs;

    @include horizontal-container-spacing;
    @include extra-desktop-space-horizontal;
  }

  @include is-desktop-wide {
    grid-template-columns: repeat(5, 1fr);
  }

  @include is-desktop-super-wide {
    grid-template-columns: repeat(6, 1fr);
  }

  &.is-category-preview {
    // Show 6 products on mobile (2 rows of 3)
    @include is-not-tablet {
      > :nth-child(n+7) {
        display: none;
      }
    }

    // Show 8 products between tablet and desktop (2 rows of 4)
    @include is-not-desktop-wide {
      > :nth-child(n+9) {
        display: none;
      }
    }

    // Show 10 products on wide desktops (2 rows of 5)
    @include is-not-desktop-super-wide {
      > :nth-child(n+11) {
        display: none;
      }
    }

    // Show 12 products on super wide desktops (2 rows of 6)
    @include is-desktop-super-wide {
      > :nth-child(n+13) {
        display: none;
      }
    }
  }
}

.grid.has-gapped-rows {
  gap: px-to-rem(12px);
}

.grid.has-gapped-rows-tiny {
  gap: px-to-rem(4px);
}

.grid.has-gapped-rows-medium {
  gap: $space-md;
}