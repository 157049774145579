@mixin extra-desktop-space-vertical($space: 70px, $properties: 'padding-top' 'padding-bottom') {
  // Elements with cotainer spacing have extra spacing on desktop if used directly inside body
  body > &,
  body > turbo-frame > & {
    @include is-desktop-wide {
      @each $property in $properties {
        #{$property}: $space;
      }
    }
  }
}

@mixin vertical-container-spacing($properties: 'padding-top' 'padding-bottom') {
  @each $property in $properties {
    #{$property}: $space-md;
  }

  @include is-tablet {
    @each $property in $properties {
      #{$property}: $space-lg;
    }
  }

  @include is-desktop {
    @each $property in $properties {
      #{$property}: $space-lg;
    }
  }

  @include is-desktop-wide {
    @each $property in $properties {
      #{$property}: $space-xl;
    }
  }
}

// Extra spacing on desktop if used in one of these locations
@mixin extra-desktop-space-horizontal($properties: 'padding-left' 'padding-right') {
  :where(body, .app-view, header, .overlay:where(.is-menu, .is-sidebar)) > &,
  :where(body, .app-view) > :where(section, turbo-frame, .cover, details.required-choice) > &,
  :where(body, .app-view) > :where(turbo-frame, .restaurant-header, section#catalog) > section > &,
  :where(body, .app-view) > section :where(.grid.has-aside, .column) > &,
  :where(body, .app-view) > .cover > :where(.cover-content, header) > &,
  :where(body, .app-view) > section .column > section > &,
  :where(.fixed-bottom-element, .overlay:where(.is-menu, .is-sidebar)) > .card > &,
  :where(.fixed-bottom-element, .overlay:where(.is-menu, .is-sidebar)) > .card > :where(a, .overlay-header) > & {
    @include is-desktop-wide {
      @each $property in $properties {
        #{$property}: $extra-desktop-container-spacing;
      }
    }
  }
}

@mixin horizontal-container-spacing($properties: 'padding-left' 'padding-right') {
  @each $property in $properties {
    #{$property}: $mobile-container-spacing;
  }

  @include is-tablet {
    @each $property in $properties {
      #{$property}: $tablet-container-spacing;
    }
  }

  @include is-desktop {
    @each $property in $properties {
      #{$property}: $desktop-container-spacing;
    }
  }

  @include is-desktop-wide {
    @each $property in $properties {
      #{$property}: $desktop-wide-container-spacing;
    }
  }
}

%trim-container-margins {
  & > :first-child {
    margin-top: 0px;
  }

  & > :last-child {
    margin-bottom: 0px;
  }
}

section {
  @extend %trim-container-margins;

  @include vertical-container-spacing;
  @include extra-desktop-space-vertical;

  // No stacked section immediately following one of these will collapse it's spacing
  $break-stack: ".cover, .bold-section, .muted-section, .video-section";

  // Excluded from having collapsed top spacing in a series of sections
  $stack-exclusions: ".bold-section, .muted-section";

  // Collapses the spacing between some stacked sections or sections following a header
  &:not(#{$break-stack}) + &:not(#{$stack-exclusions}),
  header + &:not(#{$stack-exclusions}) {
    padding-top: 0px;
  }

  // Forces a section from collapsing it's top spacing
  &:not(#{$stack-exclusions}).has-space-top {
    @include vertical-container-spacing;
    @include extra-desktop-space-vertical;

    &-above-tablet {
      padding-top: 0px;

      @include is-tablet {
        @include vertical-container-spacing;
        @include extra-desktop-space-vertical;
      }
    }

    &-above-desktop {
      padding-top: 0px;

      @include is-desktop {
        @include vertical-container-spacing;
        @include extra-desktop-space-vertical;
      }
    }
  }
}

.container {
  @extend %trim-container-margins;

  width: 100%;
  box-sizing: border-box;

  @include horizontal-container-spacing;
  @include extra-desktop-space-horizontal;
}

section,
.container {
  &.is-raised {
    @include elevation-high;

    background-color: var(--colour-bg-elevated);
  }

  &.has-gradient {
    background: linear-gradient(rgba(255, 255, 255, 0), var(--colour-bg-high-contrast));
  }

  &.has-gradient-reversed {
    background: linear-gradient(var(--colour-bg-high-contrast), rgba(255, 255, 255, 0));
  }
}

// Extends background colour to cover the full window height when there isn't enough content
@mixin extend-background {
  body > & {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 0px;
      box-shadow: 0px 0px 0px 100vh var(--colour-bg-lowered-contrast);
      z-index: $layer-background;
    }
  }
}

section.has-lowered-contrast,
.container.has-lowered-contrast {
  background-color: var(--colour-bg-lowered-contrast);

  @include extend-background;

  &-above-tablet {
    background-color: var(--colour-bg-high-contrast);

    @include is-tablet {
      background-color: var(--colour-bg-lowered-contrast);

      @include extend-background;
    }
  }

  &-above-desktop {
    background-color: var(--colour-bg-high-contrast);

    @include is-desktop {
      background-color: var(--colour-bg-lowered-contrast);

      @include extend-background;
    }
  }

  &-below-mobile {
    background-color: var(--colour-bg-lowered-contrast);

    @include is-tablet {
      background-color: var(--colour-bg-high-contrast);
    }
  }

  &-below-tablet {
    background-color: var(--colour-bg-lowered-contrast);

    @include is-desktop {
      background-color: var(--colour-bg-high-contrast);
    }
  }
}

.fixed-bottom-element {
  position: fixed;
  bottom: 0;
  z-index: $layer-middle;
  width: 100%;
}

.fixed-whole-screen {
  position: fixed;
  width: 100%;
  height: 100%;
}

%constraint {
  margin: 0 auto;
  width: 100%;
}

.width-constraint {
  @extend %constraint;

  @include is-desktop {
    max-width: 1200px;
  }

  &-narrow {
    @extend %constraint;

    @include is-tablet {
      max-width: 65ch;
    }
  }

  &-extra-narrow {
    @extend %constraint;

    @include is-tablet {
      max-width: 45ch;
    }
  }

  &-wide {
    @extend %constraint;

    @include is-desktop {
      max-width: 1600px;
    }
  }
}
