// Global utility classes
$spaces: (
  '-none': 0px,
  '-xs': $space-xs,
  '-sm': $space-sm,
  '-md': $space-md,
  '-lg': $space-lg,
  '-xl': $space-xl,
  '-extra-desktop': $extra-desktop-container-spacing,
);

// Creates a margin utility for all sides or individual sides with every space variable size
@each $suffix, $size in $spaces {
  @if $suffix == '-extra-desktop' {
    .space-m#{$suffix} {
      padding: $desktop-container-spacing !important;

      @include is-desktop-wide {
        padding: $size !important;
      }
    }

    .space-mt#{$suffix} {
      padding-top: $desktop-container-spacing !important;

      @include is-desktop-wide {
        padding-top: $size !important;
      }
    }

    .space-mb#{$suffix} {
      padding-bottom: $desktop-container-spacing !important;

      @include is-desktop-wide {
        padding-bottom: $size !important;
      }
    }

    .space-ml#{$suffix} {
      padding-left: $desktop-container-spacing !important;

      @include is-desktop-wide {
        padding-left: $size !important;
      }
    }

    .space-mr#{$suffix} {
      padding-right: $desktop-container-spacing !important;

      @include is-desktop-wide {
        padding-right: $size !important;
      }
    }
  } @else {
    .space-m#{$suffix} {
      margin: $size !important;
    }

    .space-mt#{$suffix} {
      margin-top: $size !important;
    }

    .space-mb#{$suffix} {
      margin-bottom: $size !important;
    }

    .space-ml#{$suffix} {
      margin-left: $size !important;
    }

    .space-mr#{$suffix} {
      margin-right: $size !important;
    }
  }
}

// Creates a padding utility for all sides or individual sides with every space variable size
@each $suffix, $size in $spaces {
  @if $suffix == '-extra-desktop' {
    .space-p#{$suffix} {
      padding: $desktop-container-spacing !important;

      @include is-desktop-wide {
        padding: $size !important;
      }
    }

    .space-pt#{$suffix} {
      padding-top: $desktop-container-spacing !important;

      @include is-desktop-wide {
        padding-top: $size !important;
      }
    }

    .space-pb#{$suffix} {
      padding-bottom: $desktop-container-spacing !important;

      @include is-desktop-wide {
        padding-bottom: $size !important;
      }
    }

    .space-pl#{$suffix} {
      padding-left: $desktop-container-spacing !important;

      @include is-desktop-wide {
        padding-left: $size !important;
      }
    }

    .space-pr#{$suffix} {
      padding-right: $desktop-container-spacing !important;

      @include is-desktop-wide {
        padding-right: $size !important;
      }
    }
  } @else {
    .space-p#{$suffix} {
      padding: $size !important;
    }

    .space-pt#{$suffix} {
      padding-top: $size !important;
    }

    .space-pb#{$suffix} {
      padding-bottom: $size !important;
    }

    .space-pl#{$suffix} {
      padding-left: $size !important;
    }

    .space-pr#{$suffix} {
      padding-right: $size !important;
    }
  }
}

// Visual elevation levels
.is-elevated-low-subtle {
  @include elevation-low-subtle;
}

.is-elevated-low {
  @include elevation-low;
}

.is-elevated-middle {
  @include elevation-middle;
}

.is-elevated-high {
  @include elevation-high;
}

.is-elevated-high-subtle {
  @include elevation-high-subtle;
}

// z-index utilities
.is-layer-lowest {
  z-index: $layer-lowest !important;
}

.is-layer-low {
  z-index: $layer-low !important;
}

.is-layer-middle {
  z-index: $layer-middle !important;
}

.is-layer-high {
  z-index: $layer-high !important;
}

.is-layer-highest {
  z-index: $layer-highest !important;
}

// Hides element but keeps it visible for screen readers
.is-visually-hidden {
  @include visually-hidden-element;
}

// Hides element including from screen readers
.is-completely-hidden {
  @include completely-hidden-element;
}

// Hides element inside the mobile application
body[data-app] .is-hidden-in-app {
  @include completely-hidden-element;
}

// Shows element only inside the mobile application
body:not([data-app]) .is-visible-in-app {
  @include completely-hidden-element;
}

// Hides an element completely for tablets and over
.is-hidden-above-tablet {
  @include is-tablet {
    display: none !important;
  }
}

// Hides an element completely for desktop and over
.is-hidden-above-desktop {
  @include is-desktop {
    display: none !important;
  }
}

// Shows an element only on tablet and up
.is-visible-above-tablet {
  @include is-not-tablet {
    display: none !important;
  }
}

// Shows an element only on desktop and up
.is-visible-above-desktop {
  @include is-not-desktop {
    display: none !important;
  }
}

// For making sure text can be selected and copied on mobile
.is-selectable-text {
  user-select: auto !important;
}

.is-tiny-text {
  @include tiny-text;
}

.is-muted-text {
  @include muted-text(!important);
}

.is-tiny-muted-text {
  @include tiny-muted-text;
}

.is-medium-text {
  font-size: $font-size-md !important;
}

.is-large-text {
  font-size: $font-size-lg;

  &-above-desktop {
    @include is-desktop {
      font-size: $font-size-lg;
    }
  }
}

.is-bold-text {
  @include bold-text;
}

.is-error-text {
  @include error-text;
}

.is-uppercase-text {
  text-transform: uppercase;
}

.has-monospaced-numbers {
  font-variant-numeric: tabular-nums;
}

.is-center-aligned-text {
  text-align: center;

  &-below-tablet {
    text-align: center;

    @include is-tablet {
      text-align: initial;
    }
  }
}

.is-aligned-left {
  margin-right: auto !important;
}

.is-aligned-right {
  margin-left: auto !important;
}

// For an element with a flex-direction: column
.is-vertically-aligned-center {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.is-fixed-top {
  position: fixed;
  top: 0;
}

.is-stuck-top {
  position: sticky !important;
  top: 0;
}

.is-stuck-bottom {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.is-position-absolute {
  position: absolute;
}

.is-absolute-bottom {
  position: absolute;
  bottom: 0;
}

@import "flex-utilities";
