.category-heading {
  display: flex;
  margin-bottom: $space-lg;

  h1, h2, h3, h4, h5 {
    margin: 0px;
  }

  > span {
    > * {
      display: inline-block;

      &:not(:last-child) {
        margin-right: $space-md;
      }

      &:not(:first-child) {
        margin-top: $space-xs;
      }
    }
  }
}
